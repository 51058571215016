import React from 'react'

function FormRadio({ item, checked, valueChange }) {
  return (
    <div
      className="form-control w-25 d-flex justify-content-center align-items-center bg-black border-0 text-white"
      style={{ fontFamily: 'Bebas Neue', fontSize: '20px' }}
    >
      <label className="cursor-pointer label d-flex flex-column">
        <span className="label-text text-gray-700">{item}</span>

        <input
          type="radio"
          name="opt"
          checked={checked}
          className="radio"
          value=""
          onChange={() => {
            valueChange()
          }}
          style={{ filter: 'hue-rotate(280deg)' }}
        />
      </label>
    </div>
  )
}

export default FormRadio
