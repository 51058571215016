import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const uploadImage = async (bucket, region, credentials, file, key) => {
  // Get the image in UInt8Array format
  // console.log('cred',credentials)
  // console.log('bucket',bucket)
  // console.log('region',region)
  // console.log('file',file)
  // console.log('key',key)
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const putObjectCommand = new PutObjectCommand({
    Bucket: bucket,
    Body: file,
    Key: key,
    ContentType: 'image/jpeg',
  })
  try {
    const response = await s3Client.send(putObjectCommand)
    // console.log('resp',response)
    return response
  } catch (err) {
    // console.error("PutImage",err);
  }
}

export default uploadImage
