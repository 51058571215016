import { useContext, useEffect } from 'react'
import Background from '../components/shared/Background'
import NavButton from '../components/home/NavButton'
import AccountContext from '../context/AccountContext'
import { Redirect } from 'react-router-dom'

const Logout = () => {
  const { logout, loggedIn } = useContext(AccountContext)

  useEffect(() => {
    logout()
  }, [])

  if (loggedIn == false) {
    return <Redirect to={'/home'} />
  }

  return (
    // <Background>
    <>
      <div className="row text-center">
        <div className="col">
          <h3 className="text-white">Logged out successfully.</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-2">
          <NavButton
            linkLocation={'/home'}
            imageUrl={['fas', 'house-user']}
            displayText={'Back home'}
            faIcon={true}
          />
        </div>
      </div>
    </>
    // </Background>
  )
}
export default Logout
