import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Background = ({ children }) => {
  const location = useLocation()
  const [backgroundSettings, setBackgroundSettings] = useState({
    im: 'g694.png',
    size: '100vw 100vh',
    blur: 'rgba(0,0,0,0)',
  })
  const imLocations = ['/home', '/']

  useEffect(() => {
    console.log('Hi')
    setBackgroundSettings(prevBackgroundSettings => {
      if (imLocations.includes(location.pathname)) {
        prevBackgroundSettings['im'] = 'g694.png'
        prevBackgroundSettings['blur'] = 'rgba(0,0,0,0)'
      } else {
        prevBackgroundSettings['im'] = 'g824.png'
        prevBackgroundSettings['blur'] = 'rgba(0,0,0,0.8)'
      }
      return {
        ...prevBackgroundSettings,
      }
    })
  }, [location.pathname])

  useEffect(() => {
    console.log('I changed:', backgroundSettings)
  }, [backgroundSettings])
  console.log('Pathname:', location.pathname)

  return (
    <div
      className="bg-image"
      style={{
        backgroundImage: `url(/${backgroundSettings['im']})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `100vw 100vh`,
      }}
    >
      <div
        style={{ backgroundColor: backgroundSettings.blur, height: '100vh' }}
      >
        {children}
      </div>
    </div>
  )
}

export default Background
