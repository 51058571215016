import React, { useState, useContext, useEffect } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const Status = () => {
  // const { loggedIn, logout } = useContext(AccountContext);
  const percentage = 66

  return (
    <div style={{ width: '100px', height: '100px' }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: 'round',
          textSize: '16px',
          pathTransitionDuration: 0.5,
          pathColor: `#ffff, ${percentage / 100})`,
          textColor: '#34c672',
          trailColor: '#d6d6d6',
          backgroundColor: '#34c672',
        })}
      />
      ;
    </div>
  )
  // <div>{status ? <button onClick={logout}>Logout</button> : "Please login"}</div>;
}
export default Status
