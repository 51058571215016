import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FormInput({
  label,
  type,
  image: imageUrl,
  value,
  setValue,
  onChange,
  disable = false,
  faIcon = false,
}) {
  let style = {
    border: 'none',
    background: 'transparent',
    borderBottom: '2px solid white',
    color: 'white',
    width: '100%',
  }
  return (
    <div className="container p-2">
      <div className="row align-items-center px-2">
        <div className="col-1">
          {faIcon ? (
            <FontAwesomeIcon
              icon={imageUrl}
              size="2x"
              className="text-primary"
            />
          ) : (
            imageUrl !== undefined && (
              <img src={imageUrl} alt="" width="40rem" />
            )
          )}
        </div>
        <div className="col text-start mx-2">
          <input
            type={type}
            style={style}
            placeholder={label}
            value={value}
            onChange={
              onChange === undefined ? e => setValue(e.target.value) : onChange
            }
            disabled={disable}
          />
        </div>
      </div>
    </div>
  )
}

export default FormInput
