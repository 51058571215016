import React, { useContext, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { useCSVDownloader } from 'react-papaparse'
import CountingContext from '../context/CountingContext'
import AccountContext from '../context/AccountContext'

function PrintReport(props) {
  const {
    operation,
    setOperation,
    account,
    setAccount,
    flight,
    setFlight,
    block,
    setBlock,
    csvReport,
    setCSVReport,
  } = useContext(CountingContext)

  const { creds, region, bucket, loggedIn } = useContext(AccountContext)

  console.log('Logged In', loggedIn)
  console.log(account, flight)
  console.log(csvReport)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  let block_num = 1,
    pen_num = 1

  const { CSVDownloader, Type } = useCSVDownloader()

  const pen1 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026190663702476958,
      h: 0.0431105959848799,
      x: 0.00890972647249424,
      y: 0.2651428293094976,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019341787874423963,
      h: 0.054096078845165534,
      x: 0.24420914188508064,
      y: 0.07793483304894872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017436740951420893,
      h: 0.06670836169666042,
      x: 0.2893761325724846,
      y: 0.17558627560588574,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033989605412871424,
      h: 0.03136389942026111,
      x: 0.005431900375999065,
      y: 0.47608869390823355,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022320442473557578,
      h: 0.060678428247770144,
      x: 0.48188148613388393,
      y: 0.541933729749754,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034779746733262396,
      h: 0.02826926094303195,
      x: 0.30676902454654287,
      y: 0.16152259062065497,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02908481744360536,
      h: 0.06112272636331234,
      x: 0.30697241487831634,
      y: 0.1894098345239162,
    },
  ]
  const pen2 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026190663702476958,
      h: 0.0431105959848799,
      x: 0.00890972647249424,
      y: 0.2651428293094976,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019341787874423963,
      h: 0.054096078845165534,
      x: 0.24420914188508064,
      y: 0.07793483304894872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017436740951420893,
      h: 0.06670836169666042,
      x: 0.2893761325724846,
      y: 0.17558627560588574,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033989605412871424,
      h: 0.03136389942026111,
      x: 0.005431900375999065,
      y: 0.47608869390823355,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022320442473557578,
      h: 0.060678428247770144,
      x: 0.48188148613388393,
      y: 0.541933729749754,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034779746733262396,
      h: 0.02826926094303195,
      x: 0.30676902454654287,
      y: 0.16152259062065497,
    },
  ]
  const pen3 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026190663702476958,
      h: 0.0431105959848799,
      x: 0.00890972647249424,
      y: 0.2651428293094976,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019341787874423963,
      h: 0.054096078845165534,
      x: 0.24420914188508064,
      y: 0.07793483304894872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017436740951420893,
      h: 0.06670836169666042,
      x: 0.2893761325724846,
      y: 0.17558627560588574,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033989605412871424,
      h: 0.03136389942026111,
      x: 0.005431900375999065,
      y: 0.47608869390823355,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026190663702476958,
      h: 0.0431105959848799,
      x: 0.00890972647249424,
      y: 0.2651428293094976,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019341787874423963,
      h: 0.054096078845165534,
      x: 0.24420914188508064,
      y: 0.07793483304894872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017436740951420893,
      h: 0.06670836169666042,
      x: 0.2893761325724846,
      y: 0.17558627560588574,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033989605412871424,
      h: 0.03136389942026111,
      x: 0.005431900375999065,
      y: 0.47608869390823355,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
  ]
  const pen4 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022320442473557578,
      h: 0.060678428247770144,
      x: 0.48188148613388393,
      y: 0.541933729749754,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034779746733262396,
      h: 0.02826926094303195,
      x: 0.30676902454654287,
      y: 0.16152259062065497,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02908481744360536,
      h: 0.06112272636331234,
      x: 0.30697241487831634,
      y: 0.1894098345239162,
    },
  ]
  const pen5 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017436740951420893,
      h: 0.06670836169666042,
      x: 0.2893761325724846,
      y: 0.17558627560588574,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033989605412871424,
      h: 0.03136389942026111,
      x: 0.005431900375999065,
      y: 0.47608869390823355,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022320442473557578,
      h: 0.060678428247770144,
      x: 0.48188148613388393,
      y: 0.541933729749754,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034779746733262396,
      h: 0.02826926094303195,
      x: 0.30676902454654287,
      y: 0.16152259062065497,
    },
  ]
  const pen6 = [
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01592964840374784,
      h: 0.05519543365460906,
      x: 0.03743318244242631,
      y: 0.29494374622448427,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024085203953052997,
      h: 0.053666983734852854,
      x: 0.06695662584905433,
      y: 0.3612539530213142,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02234456755232335,
      h: 0.05582270812107797,
      x: 0.17854258922571045,
      y: 0.2936347305465144,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02786524900093606,
      h: 0.0379717915441972,
      x: 0.14047893752700172,
      y: 0.25423903776191936,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016988099738383257,
      h: 0.06128574201844706,
      x: 0.19945533389136905,
      y: 0.1359580998054764,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030016134704496086,
      h: 0.0371796552390634,
      x: 0.012066841418475784,
      y: 0.11472541998386107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027144286453083,
      h: 0.030627990828314338,
      x: 0.012659626622353832,
      y: 0.17476520659396638,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021468306393484182,
      h: 0.047056379719958165,
      x: 0.016154720230219733,
      y: 0.3495437613265472,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021539278513824884,
      h: 0.05109780163553087,
      x: 0.1767490744407642,
      y: 0.5312333412412543,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028225767777262746,
      h: 0.027666781998341026,
      x: 0.010681889500303018,
      y: 0.23252048921667628,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03522238548267089,
      h: 0.02945854508388407,
      x: 0.10464819748463901,
      y: 0.052374076513080195,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025441778273809525,
      h: 0.05344862368364108,
      x: 0.19950305569556454,
      y: 0.403132945488315,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030240060363863286,
      h: 0.0404290817269547,
      x: 0.1612263434859831,
      y: 0.46694433847329414,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02889649937596006,
      h: 0.03971370602369446,
      x: 0.22449544270833333,
      y: 0.1620434795716424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02218399165046563,
      h: 0.04842808586424373,
      x: 0.1462335495721726,
      y: 0.0589479687443649,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03683221351166475,
      h: 0.03022089021025678,
      x: 0.11572574082241265,
      y: 0.3610414746037399,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03206125598179582,
      h: 0.044803421941321864,
      x: 0.007920348153368832,
      y: 0.39830095452926645,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03287556966145833,
      h: 0.027422923128245815,
      x: 0.14637919014316916,
      y: 0.2943224414400606,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030239019672259025,
      h: 0.02694330584156809,
      x: 0.014138053272909467,
      y: 0.5324184375901616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02830901607390373,
      h: 0.023745775085202682,
      x: 0.0082513425390475,
      y: 0.2058445335603902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023202794744488647,
      h: 0.04485474249151354,
      x: 0.015199583320207493,
      y: 0.04776453985620357,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02602163804108463,
      h: 0.0339151086383518,
      x: 0.013877999910744288,
      y: 0.014434261949366388,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03144947654626691,
      h: 0.05218634797299302,
      x: 0.2793310009042899,
      y: 0.47805303240289054,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02325021301363287,
      h: 0.05677685349646567,
      x: 0.17277777652769777,
      y: 0.1438374461566377,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.04212432849452466,
      h: 0.029079781999691967,
      x: 0.010311993951987928,
      y: 0.15564998532056945,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025248265889016897,
      h: 0.04036697238013019,
      x: 0.008767090781310003,
      y: 0.2918645349184489,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02327154836347026,
      h: 0.04426239242597735,
      x: 0.015994149016162036,
      y: 0.06909935142838466,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02189918904809908,
      h: 0.05284105445082588,
      x: 0.21550863005232335,
      y: 0.12359883285848242,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019550995013680874,
      h: 0.04237636031268032,
      x: 0.09239507578485023,
      y: 0.32483660328684,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02690243121301672,
      h: 0.04606900307095938,
      x: 0.12517019526749712,
      y: 0.23066291615329046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02888481151856219,
      h: 0.022000338669395558,
      x: 0.012128750849429364,
      y: 0.5101597296730741,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02605068599390361,
      h: 0.023679668340666475,
      x: 0.07600648941532258,
      y: 0.5145015375365155,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025338609170986945,
      h: 0.03653251705180684,
      x: 0.27980353242607525,
      y: 0.7493657520996375,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01939643355924779,
      h: 0.0625744537335906,
      x: 0.07475468273719518,
      y: 0.628101108945042,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030369045181391607,
      h: 0.024772155374305757,
      x: 0.0055226315734207,
      y: 0.6942810833419739,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02659454580093126,
      h: 0.05826934004407774,
      x: 0.0466409428328413,
      y: 0.6470913675157445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029001824866791474,
      h: 0.030324320674836806,
      x: 0.007811939807897704,
      y: 0.6321880738995217,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016828114499327956,
      h: 0.06088601985695867,
      x: 0.15028709962437595,
      y: 0.6919849217453927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028986266058527745,
      h: 0.028771578199383294,
      x: 0.05736346032396074,
      y: 0.706187737448946,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020016456053187404,
      h: 0.05086318344582867,
      x: 0.2590013823384697,
      y: 0.6623111511336127,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030022643714822748,
      h: 0.022682167929304314,
      x: 0.004960790537469397,
      y: 0.6738353185549129,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02647294426843318,
      h: 0.04276863357887334,
      x: 0.2142227477558564,
      y: 0.7231787996902951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024237051171274962,
      h: 0.04779390840260747,
      x: 0.4755262149277554,
      y: 0.17340478825555394,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023609260992703534,
      h: 0.05891769770178159,
      x: 0.5120923301591302,
      y: 0.3763914818166655,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019828419018817205,
      h: 0.05852276537367462,
      x: 0.44262080273137483,
      y: 0.24043961583845752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022997446836597542,
      h: 0.06022067325898009,
      x: 0.47566043601790514,
      y: 0.5084949103793999,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02745450865105367,
      h: 0.048126845846820004,
      x: 0.3248840824250252,
      y: 0.10589588163081994,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018352530811971968,
      h: 0.06294161559389426,
      x: 0.3945873348394297,
      y: 0.20374979150137046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025149934595814133,
      h: 0.055446125081145416,
      x: 0.556371826546899,
      y: 0.4041089092591424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020740177371351767,
      h: 0.06761945140065999,
      x: 0.5142742198000672,
      y: 0.22057446866660055,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.020572035360263058,
      h: 0.06784629931837853,
      x: 0.34797741117931547,
      y: 0.17732271347442116,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021964893253168204,
      h: 0.0627307572670225,
      x: 0.572828348514305,
      y: 0.32703274189222087,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025943466631864438,
      h: 0.04646310954856102,
      x: 0.4188141759822629,
      y: 0.18725480279410703,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03002132761496736,
      h: 0.03342169636739036,
      x: 0.4061920400405626,
      y: 0.1317856442494951,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02214430473550307,
      h: 0.06947970294016878,
      x: 0.4125969062019969,
      y: 0.2917480116556369,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03917620727726574,
      h: 0.024525390906754905,
      x: 0.5427644744263633,
      y: 0.1658455506955965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024245425926009263,
      h: 0.053228080031917195,
      x: 0.3359126616915982,
      y: 0.4221648553583021,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023872640348982333,
      h: 0.036238505808659116,
      x: 0.4941661266321045,
      y: 0.14742983880238386,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02846484675019201,
      h: 0.03013171478289094,
      x: 0.3666021156604023,
      y: 0.5068421008885422,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02143265919018817,
      h: 0.059039556698103,
      x: 0.560738531015985,
      y: 0.2080052767058569,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02989068261770353,
      h: 0.049939939642579124,
      x: 0.3561928751674947,
      y: 0.15502830094455303,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.019296072448636712,
      h: 0.061871774610727424,
      x: 0.41662286386328723,
      y: 0.6397663431228137,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027516898581509217,
      h: 0.036194824993575986,
      x: 0.47664016747071813,
      y: 0.7079707529251794,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.024442189300115207,
      h: 0.051099677066615876,
      x: 0.5676947574644777,
      y: 0.7226427873984556,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021767369283509145,
      h: 0.052686441446822704,
      x: 0.3246540251232329,
      y: 0.6668438875668323,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03674777685771889,
      h: 0.03026780240190421,
      x: 0.48735209053379414,
      y: 0.8183971829862864,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03001644761514737,
      h: 0.03820198530141013,
      x: 0.38632512949578773,
      y: 0.7136849272959643,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02077190440188172,
      h: 0.05506623142108158,
      x: 0.5143243417578726,
      y: 0.6410561026140094,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01904578143001152,
      h: 0.06425651290550166,
      x: 0.45010945075484826,
      y: 0.6567435025205793,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022804328227006527,
      h: 0.05671141591982833,
      x: 0.43887235345562114,
      y: 0.7873887702074618,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03014885452668971,
      h: 0.04199734713669395,
      x: 0.5840141519057219,
      y: 0.6593648709111728,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018798209335397466,
      h: 0.055168556878144384,
      x: 0.386422602921587,
      y: 0.6131543414274944,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022715213148641514,
      h: 0.03911652650299336,
      x: 0.37713326777913786,
      y: 0.6560818275412038,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02207143757200461,
      h: 0.057683470341351704,
      x: 0.5907251126572101,
      y: 0.7935491827135477,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.028668784802227343,
      h: 0.042205031318466445,
      x: 0.47176920572916664,
      y: 0.6453345376359749,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02553287160378264,
      h: 0.03310584911912146,
      x: 0.4743189376620104,
      y: 0.7763642819025444,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03217435915898618,
      h: 0.040937147454175385,
      x: 0.550309094782066,
      y: 0.6309378173264616,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023112466697868664,
      h: 0.05555977796586483,
      x: 0.5242534397201422,
      y: 0.6778929325726702,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029244083340083764,
      h: 0.040973973700715434,
      x: 0.34145731654950917,
      y: 0.6032292563586447,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02396981679922178,
      h: 0.05353649897926697,
      x: 0.5314885450359357,
      y: 0.7576540803200875,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237854472686253,
      h: 0.057270558528382864,
      x: 0.7407778410318261,
      y: 0.44532665818396566,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022207908806163596,
      h: 0.060724380590017314,
      x: 0.8616363009732623,
      y: 0.35277013674129043,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01744450394825269,
      h: 0.06115433857472591,
      x: 0.8890455864175307,
      y: 0.3266424761297244,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018275482130856373,
      h: 0.06179314738260964,
      x: 0.8285943237867224,
      y: 0.3237035963759557,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023366451702908988,
      h: 0.052890326339800925,
      x: 0.7198158744659657,
      y: 0.4118269157079486,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018047251899121545,
      h: 0.061894367832155225,
      x: 0.7812927808629753,
      y: 0.3306747057915284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016972048711117512,
      h: 0.05982868174769186,
      x: 0.8244264007896505,
      y: 0.422485703756131,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02067147297427035,
      h: 0.042291622525064916,
      x: 0.8113969044018817,
      y: 0.38247678903094345,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018881914692540323,
      h: 0.06977322111223312,
      x: 0.6617542178949453,
      y: 0.19154297156754904,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.018269538000432028,
      h: 0.05790003420504544,
      x: 0.7542489563082037,
      y: 0.22374392959057632,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027781928043394778,
      h: 0.04609503198481679,
      x: 0.9055151779713901,
      y: 0.4999928152499279,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.026350498931931643,
      h: 0.02274251630797389,
      x: 0.8626587289086501,
      y: 0.2513567555246502,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017870718725998463,
      h: 0.06960087515890977,
      x: 0.8141063403057796,
      y: 0.2763822437777247,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.030878576228878647,
      h: 0.02322146442675274,
      x: 0.7950255691364247,
      y: 0.42103741310678733,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016023387996831798,
      h: 0.05985879430314123,
      x: 0.7881077826480894,
      y: 0.23643015549489688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01935611379128264,
      h: 0.06238162772062536,
      x: 0.8051476732010849,
      y: 0.8337982054466605,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.029523839525729647,
      h: 0.035556095429259235,
      x: 0.8619899673579109,
      y: 0.7942018503408107,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.017625321770593318,
      h: 0.06165957354563753,
      x: 0.7066320275747648,
      y: 0.6356495473677893,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02235206803235407,
      h: 0.0661675331569172,
      x: 0.903573734969038,
      y: 0.742870456279528,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02917114820348502,
      h: 0.03973818348104804,
      x: 0.7456449681529618,
      y: 0.698906935368806,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02635181151593702,
      h: 0.04132816162813762,
      x: 0.7629573605210733,
      y: 0.8977770665031737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02199959234891033,
      h: 0.04916563215657458,
      x: 0.6955401676957326,
      y: 0.6863570524238811,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023241568710397464,
      h: 0.04622087076916835,
      x: 0.7302130492601526,
      y: 0.8198217533945832,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033227051531298006,
      h: 0.03598901184046812,
      x: 0.8689795334401401,
      y: 0.702566341657688,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01995150189612135,
      h: 0.05968195788102279,
      x: 0.8059342110395065,
      y: 0.5981022415997592,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.023709917434715823,
      h: 0.046660514078729086,
      x: 0.7839194958477343,
      y: 0.7922284389876659,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02018709197388633,
      h: 0.0608309191633908,
      x: 0.8456047937067973,
      y: 0.7551423792060372,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.033580726431821084,
      h: 0.03715614631419504,
      x: 0.8958976687007969,
      y: 0.9188746976769872,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.02884738060735887,
      h: 0.039417916202710256,
      x: 0.7847743161812356,
      y: 0.7148971953835023,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.027617784725722447,
      h: 0.04199192335477676,
      x: 0.6525730818647394,
      y: 0.8547834340231445,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.01585850869455645,
      h: 0.06327777560983032,
      x: 0.7270931964645737,
      y: 0.7244280921332903,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.03249234200988863,
      h: 0.036513480986052005,
      x: 0.880785138998896,
      y: 0.8707479486834158,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.025178752144245142,
      h: 0.062303534576485325,
      x: 0.6568844398228987,
      y: 0.7820347872743965,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.016354496687788017,
      h: 0.06914047003480236,
      x: 0.9706937259609615,
      y: 0.47004029658648294,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021238274874591973,
      h: 0.057277813717181186,
      x: 0.9657011626869119,
      y: 0.29832712955992136,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.015911558183473742,
      h: 0.05695330250559002,
      x: 0.9497039443336874,
      y: 0.4325796084395737,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.021185255856374808,
      h: 0.05637429617624783,
      x: 0.9596256121329265,
      y: 0.3495865880720752,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#00FF00',
      w: 0.022237334126884122,
      h: 0.052294555593623775,
      x: 0.947703158470892,
      y: 0.759776719099376,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03415323929375623,
      h: 0.04139476496732031,
      x: 0.037913898045962444,
      y: 0.5194516747712418,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03240179112484566,
      h: 0.025768926535947756,
      x: 0.040978932341555946,
      y: 0.5803102033986928,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030942250984086827,
      h: 0.04441027764705885,
      x: 0.007117601075951486,
      y: 0.5644102274509804,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.031088204998162727,
      h: 0.044958552679738584,
      x: 0.010474543399696777,
      y: 0.5857929537254902,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024599411798812143,
      h: 0.047295169283011695,
      x: 0.14664913411542138,
      y: 0.5966547646049046,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.025374196422396772,
      h: 0.04911421425543527,
      x: 0.2351682773599658,
      y: 0.5748262249358222,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03079768878748923,
      h: 0.0389275624098635,
      x: 0.303930412703102,
      y: 0.6424946979099775,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028667031072631466,
      h: 0.04547612431058834,
      x: 0.3519670593653493,
      y: 0.575190033930307,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02363093101933128,
      h: 0.046203742299557704,
      x: 0.30063757805286734,
      y: 0.43730642502060346,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024561982327625992,
      h: 0.05604358704081258,
      x: 0.06404784278309021,
      y: 0.41583887020984284,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0413005184323785,
      h: 0.036906752441510704,
      x: 0.09443192462541272,
      y: 0.3813242220932449,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.017060181672008845,
      h: 0.06722109203116611,
      x: 0.6101137301330197,
      y: 0.7635751091518483,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.019470859516966588,
      h: 0.055727330181277734,
      x: 0.6121535344633686,
      y: 0.6921744673570862,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.034305800101322115,
      h: 0.04144720182232531,
      x: 0.6151205225802396,
      y: 0.8356723425738762,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.0315000143828168,
      h: 0.03147565776465566,
      x: 0.9269583939819928,
      y: 0.9193661912379424,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.03742201708678616,
      h: 0.023192589931851804,
      x: 0.9274623942121178,
      y: 0.9548650533785316,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.033697738232703944,
      h: 0.03936493206001179,
      x: 0.9298584489553523,
      y: 0.6558420685666996,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02928004694000208,
      h: 0.04824133830883792,
      x: 0.9441388929015287,
      y: 0.6759104653031763,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.012434453572564674,
      h: 0.06146042211361169,
      x: 0.8281276309687993,
      y: 0.5795248332772264,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.01308889849743644,
      h: 0.06422614110872416,
      x: 0.79605982965008,
      y: 0.546028903225308,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.021433071289552186,
      h: 0.05070484824372978,
      x: 0.7163811600469356,
      y: 0.5825978543829068,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.02274196113929583,
      h: 0.058080098897362986,
      x: 0.737159786411616,
      y: 0.5285126829229286,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.020942237595898305,
      h: 0.0672991622144048,
      x: 0.755975078001681,
      y: 0.5537114559895093,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.029855253916712732,
      h: 0.048598771667078156,
      x: 0.9319839044540326,
      y: 0.3168540028712927,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.030982202266403802,
      h: 0.03533097358856341,
      x: 0.607729973696864,
      y: 0.5781978844101356,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.022867815958536153,
      h: 0.06269515901499978,
      x: 0.6215613139943656,
      y: 0.3973864313392521,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.024343158923602948,
      h: 0.05715304551091149,
      x: 0.6099429881444642,
      y: 0.4476118349700531,
    },
    {
      type: 'box',
      cls: 'cow',
      color: '#f44336',
      w: 0.028953605689436834,
      h: 0.06027048435696125,
      x: 0.6222989854768991,
      y: 0.4715121994564342,
    },
  ]

  const block1 = [pen1, pen2, pen3]
  const block2 = [pen4, pen5, pen6]

  // const flight = "flight-2021-1018t11_45_12_313z_field";
  // const account = "gqai";

  const blocks_arr = [block1, block2]

  const filename = account + '_' + flight

  let csv_data = []

  // for (let block of blocks_arr){
  //   for (let pen of block){
  //     csv_data.push({"Block":"block-"+block_num, "Pen":"pen-"+pen_num, "Count":pen.length})
  //     pen_num++;
  //   }
  //   block_num++;
  // }

  for (let pen of csvReport) {
    let pen_name = pen.split(':')[0]
    let pen_count = pen.split(':')[1].split(' ')[1]

    if (pen_name === 'Total') csv_data.push({ Pen: pen_name, Count: pen_count })
    else csv_data.push({ Pen: pen_name, Count: pen_count })
  }

  return (
    // <div ref={componentRef} className="w-screen h-screen">
    //   <div className="bg-black flex flex-col justify-center items-center bg-cover bg-repeat w-96 h-96 opacity-90" style={{backgroundImage: `url(/vision.png)`}}>
    //     <div className="rounded-xl shadow-lg shadow-gray-400 bg-white w-1/2 h-5/6 flex flex-row justify-center " >
    //       <header className="text-black text-7xl font-bold font-lobster"> Cattle Count Report  </header>
    //     </div>
    //   </div>
    //
    //   <button className="bg-white w-10 h-10" onClick={handlePrint}> Print </button>
    // </div>

    <CSVDownloader
      type={Type.Button}
      filename={filename}
      bom={true}
      config={{
        delimiter: ';',
      }}
      data={csv_data}
    >
      Download CSV
    </CSVDownloader>
  )
}

export default PrintReport
