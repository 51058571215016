import AWS from 'aws-sdk'

const getIdentityId = async idToken => {
  AWS.config.update({
    region: 'eu-west-2',
  })
  const cognitoidentity = new AWS.CognitoIdentity()

  const providerName = `cognito-idp.eu-west-2.amazonaws.com/eu-west-2_snqtav5O1`
  let response = await cognitoidentity
    .getId({
      IdentityPoolId: 'eu-west-2:dffaa8fe-e44a-41d3-9a80-4a2a0dc92890',
      Logins: {
        [providerName]: idToken,
      },
    })
    .promise()

  const identityId = response.IdentityId

  response = await cognitoidentity
    .getCredentialsForIdentity({
      IdentityId: identityId,
      Logins: {
        [providerName]: idToken,
      },
    })
    .promise()

  const credentials = new AWS.Credentials(
    response.Credentials.AccessKeyId,
    response.Credentials.SecretKey,
    response.Credentials.SessionToken
  )

  return {
    identityId,
    credentials,
  }
}

export default getIdentityId
