import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import AccountContext from '../context/AccountContext'
import 'react-slideshow-image/dist/styles.css'
import NavButton from '../components/home/NavButton'
import Background from '../components/shared/Background'
import CompanyLogo from '../components/shared/CompanyLogo'
import { Container, Row, Col } from 'react-bootstrap'
import FrontPageOptions from '../components/FrontPageOptions'
import FrontPageSlides from '../components/FrontPageSlides'

const Home = () => {
  const { loggedIn, selectedGroup, groupMap } = useContext(AccountContext)

  return (
    // <Background>
    <Container className="" fluid>
      <Row className="vh-100 justify-content-start align-items-center">
        <Col className="vh-100">
          <Row>
            <Container className="m-5 px-3 text-white">
              <h1
                className="fw-bold display-2"
                style={{ textShadow: '3px 3px #222222' }}
              >
                Asset Verification
              </h1>
              <h3 className="fw-bold" style={{ textShadow: '3px 3px #222222' }}>
                Livestock Model
              </h3>
            </Container>
          </Row>
          <FrontPageSlides />
        </Col>
        <Col className="text-end">
          <FrontPageOptions />
        </Col>
      </Row>
    </Container>
    // </Background>
  )
}

export default Home
