import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'

const NavButton = ({
  linkLocation,
  imageUrl,
  displayText,
  faIcon = false,
  btnwidth = '20vw',
}) => {
  return (
    <Link className="" to={linkLocation}>
      <div className="btn btn-dark" style={{ width: btnwidth }}>
        <div className="row align-items-center">
          <div className="col-2 text-primary">
            {faIcon ? (
              <FontAwesomeIcon icon={imageUrl} size="2x" />
            ) : (
              <img src={imageUrl} alt="" className="px-1" width="50rem" />
            )}
          </div>
          <div className="col-10 text-white">
            <span>{displayText}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default NavButton
