import React, { useContext } from 'react'
import DropDown from '../components/shared/Dropdown'
import AccountContext from '../context/AccountContext'
import Settings from '../components/auth/Settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
import Background from '../components/shared/Background'
import NavButton from '../components/home/NavButton'
import SideBar from '../components/shared/SideBar'
import { Container } from 'react-bootstrap'

function Profile(props) {
  const {
    groups,
    loggedIn,
    logout,
    setSelectedGroup,
    selectedGroup,
    getUsername,
  } = useContext(AccountContext)

  if (loggedIn == false) {
    return <Redirect to={'/home'} />
  }

  return (
    // <Background>
    <Container fluid>
      <div className="row no-gutters" style={{ height: '100vh' }}>
        <SideBar size="col">
          <NavButton
            linkLocation={'/home'}
            imageUrl={['fas', 'arrow-left']}
            displayText={'Back'}
            faIcon={true}
            btnwidth="12vw"
          />
          <NavButton
            linkLocation={'/logout'}
            imageUrl={['fas', 'right-from-bracket']}
            displayText={'Logout'}
            faIcon={true}
            btnwidth="12vw"
          />
        </SideBar>
        <div className="col">
          <div className="row justify-content-center">
            <div className="col text-center">
              <img
                src={`profile_pic.png`}
                alt=""
                width="150px"
                className="img-fluid m-3"
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <div className="container text-center">
                <label className="text-white">
                  {' '}
                  {getUsername == '' ? 'No username' : getUsername}{' '}
                </label>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <div className="container text-center mt-2">
                <DropDown
                  label={selectedGroup}
                  placeholder={'Select a group'}
                  items={groups}
                  setValue={setSelectedGroup}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <div className="container text-center">
                <Link to={'/change_password'}>
                  <button className="btn btn-primary">Update Password</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    // </Background>
  )
}

export default Profile
