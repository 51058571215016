import React, { useContext, useState } from 'react'
import AccountContext from '../context/AccountContext'
import { Row } from 'react-bootstrap'
import NavButton from './home/NavButton'
import Loading from './Loading'

const FrontPageOptions = () => {
  const { loggedIn, selectedGroup, groupMap, mapLoaded } = useContext(
    AccountContext
  )

  const [loading, setLoading] = useState(true)

  const display = loggedInFlag => {
    return (
      <>
        {loggedIn ? (
          <Row>
            <NavButton
              linkLocation={'/profile'}
              imageUrl={'/profile.png'}
              displayText={'Profile & Settings'}
            />
            <NavButton
              linkLocation={'/imageapp'}
              imageUrl={'/drone_g.png'}
              displayText={'Image App'}
            />
            {groupMap(selectedGroup, 'upload') && (
              <NavButton
                linkLocation={'/upload'}
                imageUrl={'upload_g.png'}
                displayText={'Upload'}
              />
            )}
            {groupMap(selectedGroup, 'admin') && (
              <NavButton
                linkLocation={'/admin-users'}
                imageUrl={'/profile.png'}
                displayText={'Admin - Users'}
              />
            )}
            {groupMap(selectedGroup, 'admin') && (
              <NavButton
                linkLocation={'/admin-labelers-counters'}
                imageUrl={'/profile.png'}
                displayText={'Admin - Labelers & Counters'}
              />
            )}
          </Row>
        ) : (
          <NavButton
            linkLocation={'/login'}
            imageUrl={['fas', 'arrow-right-to-bracket']}
            displayText={'Login'}
            faIcon={true}
          />
        )}
      </>
    )
  }

  return (
    <>{loggedIn & !mapLoaded & !loading ? <Loading /> : display(loggedIn)}</>
  )
}

export default FrontPageOptions
