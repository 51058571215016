import listObjects from './base/ListS3Objects'

const listObjectsForPrefix = async (
  bucket,
  region,
  credentials,
  prefix = '',
  key_num = 0
) => {
  let objectKeys = await listObjects(
    bucket,
    region,
    credentials,
    prefix.endsWith('/') ? prefix : prefix + '/'
  )
  if (objectKeys == undefined) {
    return []
  } else {
    let unique = new Set(objectKeys.map(el => el.Key.split('/')[key_num]))
    unique = Array.from(unique).filter((value, index, array) => {
      return value.length !== 0
    })
    return unique
  }
}

export default listObjectsForPrefix
