import React, { createContext, useEffect, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import Pool from '../components/auth/UserPool'
import getIdentityId from '../components/aws/Identity'
import getJsonForPath from '../components/aws/GetJsonForFlight'
import getObject from '../components/aws/base/GetS3Object'

const AccountContext = createContext(null)

export const AccountProvider = ({ children }) => {
  const [map, setMap] = useState(null)
  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    if (map !== null) {
      setMapLoaded(true)
    }
  }, [map])

  const groupMap = (group, info, operation = '') => {
    console.log('Getting:', group, info, operation)
    if (map === null) {
      return null
    }
    if (group !== '') {
      let mapGroup = map[group]
      if (mapGroup === undefined) {
        return null
      }
      if (operation === '') {
        return map[group][info]
      } else {
        return map[group][info][operation]
      }
    }
  }

  // Pages and the user groups that can access them
  const routePrivileges = {
    Home: ['User', 'Admin', 'Counter', 'Labeller'],
    Profile: ['User', 'Admin', 'Counter', 'Labeller'],
    ImageApp: ['Admin', 'Counter', 'Labeller'],
    Upload: ['Admin'],
  }

  const region = 'af-south-1'
  const bucket = 'cattle-data'
  // const region = 'eu-west-2';
  // const bucket = 'test-cattle-data';
  // Signed in user credentials
  const [creds, setCreds] = useState({})

  // Bool indicating login status
  const [loggedIn, setLoggedIn] = useState(false)
  const [groups, setGroups] = useState([])
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(
    localStorage.getItem('selectedGroup')
  )
  const [getUsername, setGetUsername] = useState('')
  const [userConfig, setUserConfig] = useState({})
  const [cognitoUser, setCognitoUser] = useState({})
  const [userAttributes, setUserAttributes] = useState({})

  useEffect(() => {
    getSession()
      .then(res => {
        setGetUsername(res['user'].getUsername())
      })
      .catch(() => {})
    // console.log(`Timer set: ${new Date()}`)
    setInterval(() => {
      getSession()
        .then(() => {})
        .catch(() => {})
    }, 50 * 60000)
  }, [])

  useEffect(() => {
    // console.log('LoggedIn changed');
  }, [loggedIn])

  const getSession = async () => {
    // console.log('GetSession called')
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      // console.log('User:',user);
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err)
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}

                  for (let attribute of attributes) {
                    const { Name, Value } = attributes
                    results[Name] = Value
                  }

                  resolve(results)
                }
              })
            })
            getIdentityId(
              user['signInUserSession']['idToken']['jwtToken']
            ).then(data => {
              setCreds(data.credentials)
              setLoggedIn(true)
              // console.log('user',user)
              let userGroups =
                user.signInUserSession.idToken.payload['cognito:groups']
              if (userGroups === null || userGroups === undefined) {
                userGroups = []
              }
              getUserPrefix(user.username, data.credentials, userGroups) //cognito user id
              setGroups(userGroups)
              setSelectedGroup(prevSelectedGroup => {
                if (
                  prevSelectedGroup !== null &&
                  userGroups.includes(prevSelectedGroup)
                ) {
                  return prevSelectedGroup
                } else {
                  return userGroups.length > 0 ? userGroups[0] : null
                }
              })
              getJsonForPath(
                bucket,
                region,
                data.credentials,
                'config.json',
                false,
                true
              ).then(config => {
                setMap(config['config'])
                console.log(config['config'])
              })
            })

            resolve({ user, ...session, ...attributes })
          }
        })
      } else {
        reject()
      }
    })
  }

  useEffect(() => {
    localStorage.setItem('selectedGroup', selectedGroup)
  }, [selectedGroup])

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })

      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: data => {
          // console.log("successful data: ", data);
          setGetUsername(Username)
          getIdentityId(data['idToken']['jwtToken']).then(data => {
            setCreds(data.credentials)
            setLoggedIn(true)
            let userGroups =
              user.signInUserSession.idToken.payload['cognito:groups']
            if (userGroups === null || userGroups === undefined) {
              userGroups = []
            }
            getUserPrefix(user.username, data.credentials, userGroups) //cognito user id
            setGroups(userGroups)
            setSelectedGroup(userGroups.length !== 0 ? userGroups[0] : '')
          })
          resolve(data)
        },
        onFailure: err => {
          console.log('Failed to login')
          setLoggedIn(false)
          reject(err)
        },
        newPasswordRequired: data => {
          setNewPasswordRequired(true)
          setCognitoUser(user)
          resolve(data)
        },
      })
    })
  }

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      user.signOut()
      setLoggedIn(false)
    }
  }

  const getUserPrefix = async (username, creds, userGroups) => {
    let userGroupsLower = userGroups.map(group => {
      return group.toLowerCase()
    })

    // If the user is a counter or a labeller:
    let response = null
    if (
      userGroupsLower.includes('labeler') ||
      userGroupsLower.includes('counter')
    ) {
      // User is a normal user, get prefixes as per normal, from user directory
      response = await getObject(
        bucket,
        region,
        creds,
        `users_labeler_counter/${username}.json`
      )
    } else {
      // User is a normal user, get prefixes as per normal, from user directory
      response = await getObject(
        bucket,
        region,
        creds,
        `users/${username}.json`
      )
    }

    let default_config = '{"userPrefix":[], "farmerName":""}'

    if (!response) setUserConfig(JSON.parse(default_config))
    else {
      let jsonObject = await new Response(response.Body, {}).text()
      setUserConfig(JSON.parse(jsonObject))
    }
  }

  const updateNewPassword = async (password, newPassword) => {
    if (newPasswordRequired) {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: result => {
            // login
            setNewPasswordRequired(false)
            return true
          },
          onFailure: err => {
            setNewPasswordRequired(true)
            throw err
          },
        }
      )
    } else {
      getSession().then(({ user }) => {
        user.changePassword(password, newPassword, (err, result) => {
          if (err) {
            console.error(err)
            throw err
          } else {
            return true
          }
        })
      })
    }
  }

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        creds,
        setCreds,
        loggedIn,
        region,
        bucket,
        userConfig,
        groups,
        selectedGroup,
        setSelectedGroup,
        newPasswordRequired,
        setNewPasswordRequired,
        getUsername,
        setGetUsername,
        groupMap,
        mapLoaded,
        updateNewPassword,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
export default AccountContext
