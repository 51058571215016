import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const putJsonObject = async (bucket, region, credentials, jsonObject, key) => {
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const putObjectCommand = new PutObjectCommand({
    Bucket: bucket,
    Body: JSON.stringify(jsonObject),
    Key: key,
    ContentType: 'application/json',
  })
  try {
    const response = await s3Client.send(putObjectCommand)
    return response
  } catch (err) {
    console.error('PutJson', err)
    return err
  }
}

export default putJsonObject
