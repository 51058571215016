const CompanyLogo = ({
  size = 'col',
  children,
  notext = false,
  imsize = '40%',
}) => {
  return (
    <div className={size}>
      <div className="text-center">
        <img
          src={'/veriphy_logo_grey.png'}
          alt=""
          className="img-fluid"
          style={{ maxWidth: imsize }}
        />
      </div>
      {!notext && (
        <div className="text-center">
          <h1 className="text-white">Asset Verification</h1>
        </div>
      )}
      {children}
    </div>
  )
}
export default CompanyLogo
