import React from 'react'
import { Fade } from 'react-slideshow-image'

const FrontPageSlides = () => {
  const slideImages = [
    {
      url: '/drone2.jpg',
    },
    {
      url: '/drone3.jpg',
    },
    {
      url: '/slideshow_1.jpeg',
    },
    {
      url: '/drone4.jpg',
    },
    {
      url: '/cattle.jpg',
    },
    {
      url: '/drone5.jpg',
    },
    {
      url: '/slideshow_2.jpeg',
    },
  ]

  return (
    <div
      className="m-3 slide-container"
      style={{
        backgroundColor: `rgba(0,0,0,0.8)`,
        width: `26%`,
        position: 'absolute',
        top: `39%`,
        left: `4.5%`,
        borderStyle: `solid`,
        borderColor: `#91ff92`,
        borderWidth: `1rem`,
        borderRadius: `5px`,
      }}
    >
      <Fade>
        {slideImages.map((slideImage, index) => (
          <img
            src={slideImage.url}
            alt=""
            width="100%"
            height="100%"
            style={{ maxHeight: '42vh' }}
          />
        ))}
      </Fade>
    </div>
  )
}

export default FrontPageSlides
