function FormCheckbox({ label, setFunction }) {
  return (
    <div className="row justify-content-center">
      <div className="col-3">
        <div className="form-check">
          <label htmlFor="checkbox" className="form-check-label">
            {label}
          </label>
          <input
            type="checkbox"
            className="form-check-input"
            value={''}
            id="checkbox"
            onChange={e => setFunction(e.target.checked)}
          />
        </div>
      </div>
    </div>
  )
}

export default FormCheckbox
