import React, { useState, useContext, useEffect } from 'react'
import AccountContext from '../context/AccountContext'
import CountingContext from '../context/CountingContext'
import putTextObject from './aws/base/PutText'
import DropDown from './shared/Dropdown'
import FormRadio from './shared/FormRadio'
import listObjectsForPrefix from './aws/ListObjectsForPrefix'
import { useCSVDownloader } from 'react-papaparse'
import { CircularProgressbar } from 'react-circular-progressbar'
import Background from './shared/Background'
import SideBar from './shared/SideBar'
import NavButton from './home/NavButton'

export const NavBar = props => {
  const {
    creds,
    region,
    bucket,
    selectedGroup,
    groupMap,
    userConfig,
  } = useContext(AccountContext)
  const {
    operation,
    setOperation,
    account,
    setAccount,
    flight,
    setFlight,
    block,
    setBlock,
    setDone,
    displayBoxes,
    setDisplayBoxes,
    feedback,
    csvReport,
    setFeedback,
    prepareCSV,
    setPrepareCSV,
    csvReady,
    setCSVReady,
  } = useContext(CountingContext)
  const [operationDropdownOptions, setOperationDropdownOptions] = useState(
    groupMap(selectedGroup, 'operations')
  )
  const [accountDropdownOptions, setAccountDropdownOptions] = useState([])
  const [flightDropdownOptions, setFlightDropdownOptions] = useState([])
  const [blockDropdownOptions, setBlockDropdownOptions] = useState([])
  const [checked, setChecked] = useState(0)

  useEffect(() => {
    setOperationDropdownOptions(groupMap(selectedGroup, 'operations'))
    if (selectedGroup === '') {
      setFeedback('No group selected!')
    }
  }, [selectedGroup])

  const doneClicked = () => {
    putTextObject(
      bucket,
      region,
      creds,
      `${operation}/${account}/${flight}/${block}/done.txt`
    ).then(() => {
      setDone(true)
    })
  }

  const dotsClicked = () => {
    setDisplayBoxes(false)
  }
  const boxesClicked = () => {
    setDisplayBoxes(true)
  }

  useEffect(() => {
    if (checked === 0) {
      // Dots
      setDisplayBoxes(false)
    } else if (checked === 0) {
      // Boxes
      setDisplayBoxes(true)
    }
  }, [checked])

  useEffect(() => {
    // operation changed
    setAccount('')
    setFlight('')
    if (operation !== '') {
      if (Object.keys(creds).length > 0) {
        // Here is where the user should not see the dropdown
        let prefix = ''
        if (
          selectedGroup.toLowerCase() === 'user' ||
          selectedGroup.toLowerCase() === 'labeler' ||
          selectedGroup.toLowerCase() === 'counter'
        ) {
          setFeedback('Loading accounts...')
          setAccountDropdownOptions(userConfig['userPrefix'])
          setFlightDropdownOptions([])
          setBlockDropdownOptions([])
          setFeedback('Accounts loaded')
        } else {
          setFeedback('Loading accounts...')
          listObjectsForPrefix(
            bucket,
            region,
            creds,
            groupMap(selectedGroup, 'json_location', operation),
            1
          ).then(accounts => {
            if (accounts.length == 0) {
              setFeedback('No accounts found.')
            } else {
              setFeedback('Accounts loaded')
            }
            setAccountDropdownOptions(accounts)
            setFlightDropdownOptions([])
            setBlockDropdownOptions([])
          })
        }
        // When operation is set, reset account and flight
        setAccount('')
        setFlight('')
        setBlock('')
      } else {
        // No credentials, make sure mode is void
        // console.log("No credentials")
        setOperation('')
        setAccount('')
        setFlight('')
        setBlock('')
      }
    } else {
      // No operation selected, make sure mode is void
      setAccount('')
      setFlight('')
      setBlock('')
    }
  }, [operation])

  useEffect(() => {
    // account changed
    if (account !== '') {
      if (Object.keys(creds).length > 0) {
        setFeedback('Loading flights...')
        listObjectsForPrefix(
          bucket,
          region,
          creds,
          `${groupMap(selectedGroup, 'json_location', operation)}/${account}/`,
          2
        ).then(flights => {
          if (flights.length == 0) {
            setFeedback('No flights found.')
          } else {
            setFeedback('Flights loaded')
          }
          setFlightDropdownOptions(flights)
          setBlockDropdownOptions([])
        })
        setFlight('')
        setBlock('')
      } else {
        // No credentials, make sure mode is void
        setOperation('')
        setAccount('')
        setFlight('')
        setBlock('')
      }
    } else {
      // No account selected, void account and flight
      setFlight('')
      setBlock('')
    }
  }, [account])

  useEffect(() => {
    if (flight !== '') {
      if (Object.keys(creds).length > 0) {
        setFeedback('Loading blocks...')
        listObjectsForPrefix(
          bucket,
          region,
          creds,
          `${groupMap(
            selectedGroup,
            'json_location',
            operation
          )}/${account}/${flight}/`,
          3
        ).then(blocks => {
          if (blocks.length == 0) {
            setFeedback('No blocks found.')
          } else {
            setFeedback('Blocks loaded')
          }
          setBlockDropdownOptions(blocks)
        })
        setBlock('')
      } else {
        // No credentials, make sure mode is void
        setOperation('')
        setAccount('')
        setFlight('')
        setBlock('')
      }
    } else {
      // No account selected, void account and flight
      setBlock('')
    }
  }, [flight])

  useEffect(() => {
    return () => {
      setCSVReady(false)
      setPrepareCSV(false)
    }
  }, [operation, account, flight, block])

  //DOWNLOAD CSV
  const { CSVDownloader, Type } = useCSVDownloader()

  const filename = account + '_' + flight

  //ON THE NEW STRUCTURE
  // for (let pen of csvReport){
  //   let block_name = pen.split(":")[0]
  //   let pen_name = pen.split(":")[1];
  //   let pen_count = pen.split(":")[2];
  //
  //   if (pen_name === "Total") csv_data.push({"Block":"", "Pen":"", "Count":""}, {"Block":block_name, "Pen":pen_name, "Count":pen_count})
  //   else csv_data.push({"Block":block_name, "Pen":pen_name, "Count":pen_count})
  // }

  // if (loggedin) {
  //   return <Redirect to="/home"/>;
  // }
  const percentage = 66

  return (
    // <Background>
    <div className="m-0" /*style={{backgroundImage: `url(/bg.jpg)`}}*/>
      <div
        className="row no-gutters"
        style={{ height: '100vh', width: '100vw' }}
      >
        <SideBar size="col-2">
          <NavButton
            linkLocation={'/home'}
            imageUrl={['fas', 'arrow-left']}
            displayText={'Back'}
            faIcon={true}
            btnwidth="12vw"
          />
          <DropDown
            image={'/operation_b.png'}
            label={operation}
            placeholder={'Operation'}
            items={operationDropdownOptions}
            setValue={setOperation}
          />
          <DropDown
            image={'/account.png'}
            label={account}
            placeholder={'Account'}
            items={accountDropdownOptions}
            setValue={setAccount}
          />
          <DropDown
            image={'/drone_b.png'}
            label={flight}
            placeholder={'Flight'}
            items={flightDropdownOptions}
            setValue={setFlight}
          />
          <DropDown
            image={'/block_b.png'}
            label={block}
            placeholder={'Block'}
            items={blockDropdownOptions}
            setValue={setBlock}
          />
          <div
            className="d-flex flex-row justify-content-between"
            style={{ margin: '-20px 0 0 0' }}
          >
            {block !== '' && groupMap(selectedGroup, 'box_dots') && (
              <FormRadio
                item={'Boxes'}
                checked={displayBoxes === true}
                valueChange={boxesClicked}
              />
            )}
            {block !== '' && groupMap(selectedGroup, 'box_dots') && (
              <FormRadio
                item={'Dots'}
                checked={displayBoxes !== true}
                valueChange={dotsClicked}
              />
            )}
          </div>
          {selectedGroup === 'Admin' && (
            <div
              className="w-100 d-flex flex-column justify-content-end align-items-center"
              style={{ margin: '10% 0 0 0' }}
            >
              {!csvReady && block !== '' && !prepareCSV && (
                <button
                  className="btn bg-success text-black"
                  style={{
                    fontSize: '20px',
                    margin: '10px 5px 0 0',
                    visibility: csvReady ? 'hidden' : 'visible',
                  }}
                  onClick={() => setPrepareCSV(true)}
                >
                  Prepare CSV
                </button>
              )}

              {prepareCSV && !csvReady && block !== '' && (
                <div
                  style={{ width: '50px', height: '50px', margin: '5px 0 0 0' }}
                >
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                  />
                  ;
                </div>
              )}

              {csvReady && block !== '' && (
                <CSVDownloader
                  className="btn bg-success text-black"
                  // style={{fontSize: '20px', margin: '10px 5px 0 0'}}
                  type={Type.Button}
                  filename={filename}
                  bom={true}
                  config={{ delimiter: ';' }}
                  data={csvReport}
                >
                  Download CSV
                </CSVDownloader>
              )}
            </div>
          )}
          {block !== '' && (
            <div>
              <button
                className="btn btn-primary text-black"
                onClick={doneClicked}
              >
                Done
              </button>
            </div>
          )}

          <p className={'text-white'}>{feedback}</p>
        </SideBar>
        <div className="col flex-nowrap" style={{ height: '100vh' }}>
          {props.children}
        </div>
      </div>
    </div>
    // </Background>
  )
}
