import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const putTextObject = async (bucket, region, credentials, key) => {
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const putObjectCommand = new PutObjectCommand({
    Bucket: bucket,
    Body: '',
    Key: key,
    ContentType: 'text/plain',
  })
  try {
    const response = await s3Client.send(putObjectCommand)
    return response
  } catch (err) {
    console.error('PutText', err)
  }
}

export default putTextObject
