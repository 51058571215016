import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import getObject from './base/GetS3Object'
import listObjects from './base/ListS3Objects'

const getJsonForPath = async (
  bucket,
  region,
  credentials,
  path = '',
  return_keys = false,
  single_file = false
) => {
  let s3Objects = []
  let jsonObjects = {}

  //NEW STRUCTURE
  // let blocks = {};

  if (single_file) {
    let s3Path = path.endsWith('.json') ? path : path + '.json'
    s3Objects = await listObjects(bucket, region, credentials, s3Path)
  } else if (typeof path == 'object') {
    // Multi jsons
    for (let pref of path) {
      let s3Path = pref.endsWith('/') ? pref : pref + '/'
      const s3List = await listObjects(bucket, region, credentials, s3Path)
      s3Objects = s3Objects.concat(s3List)
    }
  } else {
    let s3Path = path.endsWith('/') ? path : path + '/'
    s3Objects = await listObjects(bucket, region, credentials, s3Path)
  }

  for (let s3Object of s3Objects) {
    if (s3Object.Key.toLowerCase().endsWith('.json')) {
      // arr.push(s3Object.Key);
      let response = await getObject(bucket, region, credentials, s3Object.Key)
      let jsonObject = await new Response(response.Body, {}).text()
      let numberOfLevels = s3Object.Key.split('/').length
      let filename = return_keys
        ? s3Object.Key
        : s3Object.Key.split('/')[numberOfLevels - 1].split('.')[0]
      jsonObjects[filename] = JSON.parse(jsonObject)

      //NEW STRUCTURE
      // let block_name = s3Object.Key.split("/")[4];
      // blocks[block_name] = jsonObjects;
    }
  }
  return jsonObjects
  //NEW STRUCTURE
  // return blocks;
}

export default getJsonForPath
