import { createContext, useContext, useEffect, useState } from 'react'
import getJsonForPath from '../components/aws/GetJsonForFlight'
import getMultipleImageUrls from '../components/aws/GetMultipleSignedImageUrls'
import AccountContext from './AccountContext'

const CountingContext = createContext(null)

export const CountingProvider = ({ children }) => {
  const changeRegions = (regions, operand) => {
    let newRegions = []
    switch (operand) {
      case 'ASIS':
        newRegions = [...regions]
        break
      case 'DOTSONLY': //Convert regions to dots
        newRegions = regions.map(region => {
          if (region['type'] === 'point') {
            return {
              ...region,
              color: '#41ee11',
            }
          } else {
            return {
              ...region,
              x: region.x + region.w / 2,
              y: region.y + region.h / 2,
              type: 'point',
              color: '#41ee11',
            }
          }
        })
        break
      case 'ADDDOTS': //Add dots to regions
        break
    }
    return newRegions
  }

  const {
    loggedIn,
    creds,
    region,
    bucket,
    selectedGroup,
    groupMap,
  } = useContext(AccountContext)

  // Current operation in the counting process
  const [operation, setOperation] = useState('')
  // Current account in the counting process
  const [account, setAccount] = useState('')
  // Current flight in the counting process
  const [flight, setFlight] = useState('')
  // Current block in the counting process
  const [block, setBlock] = useState('')
  const [done, setDone] = useState(false)
  // Display points need to be localized to another context
  const [feedback, setFeedback] = useState('')

  const [images, setImages] = useState([])
  const [displayImages, setDisplayImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(0)
  const [numberOfMatches, setNumberOfMatches] = useState(0)
  const [displayBoxes, setDisplayBoxes] = useState(true)
  const [displayDots, setDisplayDots] = useState(true)
  const [updates, setUpdates] = useState(0)
  const [enabledTools, setEnabledTools] = useState(['select', 'create-box'])
  const [csvReport, setCSVReport] = useState([])
  const [prepareCSV, setPrepareCSV] = useState(false)
  const [csvReady, setCSVReady] = useState(false)

  //REPORT
  useEffect(() => {
    // setDone(false);
    if (loggedIn && flight.length > 0) {
      getJsonForPath(
        bucket,
        region,
        creds,
        `${groupMap(
          selectedGroup,
          'json_location',
          operation
        )}/${account}/${flight}/`,
        true
      ).then(jsonObjects => {
        let csv_data = []
        let cattleReport = []
        let totalCattle = 0
        // console.log('jsonObjects',jsonObjects)
        for (let key of Object.keys(jsonObjects)) {
          let key_breakup = key.split('/')
          let block_name = key_breakup[key_breakup.length - 2]
          let pen_name = key_breakup[key_breakup.length - 1].split('.json')[0]
          let jsonObject = jsonObjects[key]
          let cattle = 0
          if ('regions' in jsonObject) {
            cattle = jsonObject.regions.length
          } else {
            cattle = jsonObject.length
          }
          csv_data.push({ Block: block_name, Pen: pen_name, Count: cattle })
          totalCattle += cattle
          cattleReport = cattleReport.concat(
            `${block_name}:${pen_name}:${cattle}`
          )
        }

        setCSVReport(csv_data)
        setCSVReady(true)
      })
    }
  }, [prepareCSV])

  useEffect(() => {
    // Make sure done flag not set
    setDone(false)
    if (loggedIn && block.length !== 0) {
      switch (operation) {
        // case 'report':
        //   console.log('UseEffect, block for report');
        //   getJsonForPath(
        //     bucket,
        //     region,
        //     creds,
        //     `${groupMap(selectedGroup, 'json_location', operation)}/${account}/${flight}/${block}/`
        //   ).then((jsonObjects)=>{
        //     console.log('Report');
        //     console.log(jsonObjects);
        //     let cattleReport = [];
        //     let totalCattle = 0;
        //     console.log(jsonObjects)
        //     for (let key of Object.keys(jsonObjects)){
        //       let jsonObject = jsonObjects[key];
        //       console.log(typeof(jsonObject))
        //       let cattle = 0;
        //       if('regions' in jsonObject){
        //         cattle = jsonObject.regions.length;
        //       }else{
        //         cattle = jsonObject.length;
        //       }
        //       totalCattle += cattle;
        //       cattleReport = cattleReport.concat(`${key}: ${cattle}`);
        //     }
        //     cattleReport = cattleReport.concat('----------------------------');
        //     cattleReport = cattleReport.concat(`Total number of cattle: ${totalCattle}`);
        //     // setReport(cattleReport);
        //   })
        //   break;
        default:
          getImages(operation, account, flight, block)

          break
      }
    }
  }, [block])

  useEffect(() => {
    setEnabledTools(groupMap(selectedGroup, 'enabled_tools', operation))
    setImages([])
    // setReport([]);
    setDisplayImages([])
    setNumberOfMatches(0)
    setUpdates(updates + 1)
  }, [operation, account, flight])

  useEffect(() => {
    // Change displayImages according to stuff
    if (displayBoxes) {
      setDisplayImages(images)
      if (operation === 'feedlots') {
        setEnabledTools(['select', 'create-box', 'create-polygon'])
      } else {
        setEnabledTools(['select', 'create-box'])
      }
    } else {
      setDisplayImages(boxesToDots(images))
      setEnabledTools([])
    }
    setUpdates(updates + 1)
  }, [displayBoxes])

  const boxesToDots = ims => {
    const newImages = []
    for (let im of ims) {
      newImages.push({
        ...im,
        regions: im.regions.map(region => {
          if (region.type === 'box') {
            return {
              ...region,
              x: region.x + region.w / 2,
              y: region.y + region.h / 2,
              type: 'point',
              color: '#41ee11',
            }
          } else {
            return {
              ...region,
            }
          }
        }),
      })
    }
    return newImages
  }

  const getImages = (op, account, flight, block) => {
    getMultipleImageUrls(
      bucket,
      region,
      creds,
      `${groupMap(
        selectedGroup,
        'image_location',
        op
      )}/${account}/${flight}/${block}/`
    ).then(imageUrls => {
      let imageObjects = []
      for (let [key, value] of Object.entries(imageUrls)) {
        imageObjects.push({
          src: value,
          name: key
            .split('/')
            .splice(-1)[0]
            .toLowerCase()
            .split('.')[0], //gets last element name without .jpg
          regions: [],
        })
      }
      // TODO check for non-confirming dots
      // TODO deploy
      getJsonForPath(
        bucket,
        region,
        creds,
        `${groupMap(
          selectedGroup,
          'json_location',
          operation
        )}/${account}/${flight}/${block}/`
      ).then(jsonObjects => {
        // This is criminal. JS allowing me to modify the object while iterating over its array.
        let numMatched = 0
        for (let image of imageObjects) {
          let matchedJson = jsonObjects[image.name]
          if (matchedJson) {
            numMatched++
            if (!matchedJson.hasOwnProperty('regions')) {
              image.regions = matchedJson
              for (let region of image.regions) {
                region['cls'] = 'Cow'
              }
            } else {
              image.regions = matchedJson.regions
            }
            let i = 0
            for (let region of image.regions) {
              region['id'] = String(i++).padStart(10, '0')
            }
            image.regions = changeRegions(
              image.regions,
              groupMap(selectedGroup, 'region_change', operation)
            )
          }
        }
        if (op === 'feedlots' || op === 'merged') {
          setNumberOfMatches(imageObjects.length)
        } else {
          setNumberOfMatches(numMatched)
        }
        setImages(imageObjects)
        setDisplayImages(imageObjects)
        setUpdates(updates + 1)
      })
    })
  }

  const changeHook = history => {
    // Update internal state of images
    setImages(history.images)
  }

  const deleteHook = action => {
    const newImages = []
    images.forEach(image => {
      newImages.push({
        ...image,
        regions: image.regions.filter(region => region.id !== action.region.id),
      })
    })
    setImages(newImages)
  }

  return (
    <CountingContext.Provider
      value={{
        operation,
        setOperation,
        account,
        setAccount,
        flight,
        setFlight,
        done,
        setDone,
        images,
        selectedImage,
        setSelectedImage,
        // report,
        numberOfMatches,
        displayBoxes,
        setDisplayBoxes,
        displayDots,
        setDisplayDots,
        displayImages,
        setDisplayImages,
        updates,
        changeHook,
        deleteHook,
        enabledTools,
        feedback,
        setFeedback,
        block,
        setBlock,
        groupMap,
        csvReport,
        setCSVReport,
        prepareCSV,
        setPrepareCSV,
        csvReady,
        setCSVReady,
      }}
    >
      {children}
    </CountingContext.Provider>
  )
}

export default CountingContext
