import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'

const UploadForm = ({ index = 0, updateFiles, subtract }) => {
  const [imagesUploaded, setImagesUploaded] = useState(0)
  const [blockName, setBlockName] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(false)

  let style = {
    border: 'none',
    background: 'transparent',
    borderBottom: '2px solid white',
    color: 'white',
    width: '100%',
  }

  useEffect(() => {
    if (blockName.length === 0 || blockName === undefined || blockName === '') {
      setBtnDisabled(true)
    } else {
      setBtnDisabled(false)
    }
  }, [blockName])

  const fileInput = useRef()

  const handleBlockName = newName => {
    if (/^[a-zA-Z0-9_-]*$/.test(newName)) {
      setBlockName(newName)
    }
  }

  return (
    <div className="container px-1">
      <div className="row align-items-center px-2 my-2">
        <div className="col">
          <div className="row m-1">
            <input
              type="text"
              value={blockName}
              onChange={e => handleBlockName(e.target.value)}
              placeholder="Block name..."
              style={style}
            />
          </div>
          <div className="row m-1">
            <input
              type="file"
              ref={fileInput}
              disabled={btnDisabled}
              multiple="multiple"
              onChange={e => updateFiles(e.target.files, blockName)}
            />
          </div>
        </div>
        <div className="col-2 m-1">
          <div
            className="btn text-danger"
            onClick={() => {
              subtract(index)
            }}
          >
            <FontAwesomeIcon icon={['fas', 'circle-minus']} size="xl" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default UploadForm
