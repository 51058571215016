import { Col, Row } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

function Loading() {
  return (
    <Row>
      <Col className="text-center">
        <Spinner
          variant="light"
          size="lg"
          animation="border"
          role="status"
          style={{ width: '5rem', height: '5rem' }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  )
}

export default Loading
