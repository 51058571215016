import React, { useState, useContext } from 'react'
import AccountContext from '../context/AccountContext'
import FormInput from '../components/shared/FormInput'
import { Link, Redirect } from 'react-router-dom'
import Background from '../components/shared/Background'
import NavButton from '../components/home/NavButton'
import CompanyLogo from '../components/shared/CompanyLogo'
import { Button, Col, Container, Row } from 'react-bootstrap'

const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [newPasswordSet, setNewPasswordSet] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')

  const { updateNewPassword } = useContext(AccountContext)

  const onSubmit = event => {
    event.preventDefault()
    if (newPassword === newPassword2) {
      // If new password is the same as old password, updating doesn't work, check for this:
      if (newPassword === password) {
        setFeedbackText(
          "Your old password can't be the same as your new password, please try again"
        )
        return
      }

      updateNewPassword(password, newPassword)
        .then(res => {
          setNewPasswordSet(true)
        })
        .catch(err => {
          setFeedbackText(err.message)
        })
    } else {
      setFeedbackText(
        "Password and confirmation didn't match, please try again!"
      )
      return
    }
  }

  if (newPasswordSet) return <Redirect to="/home" />

  return (
    <Background>
      <div className="row h-100 align-items-center justify-content-around">
        <CompanyLogo size="col-6">
          <div className="row justify-content-center">
            <div className="col-6 text-center m-2">
              <NavButton
                linkLocation={'/home'}
                imageUrl={['fas', 'house-user']}
                displayText={'Home'}
                faIcon={true}
              />
            </div>
          </div>
        </CompanyLogo>
        <div className="col-6 p-5">
          <form
            onSubmit={onSubmit}
            className=" d-flex flex-column justify-content-center align-items-center"
          >
            <h1 className="text-white" style={{ margin: '0 0 20px 0' }}>
              Password change required
            </h1>

            <p
              style={{
                color: 'white',
                margin: '0 20px 20px 20px',
                textAlign: 'center',
              }}
            >
              A password change is required for your account. <br /> Please
              enter your current or temporary password, followed by your new
              password. Your new password requires at least one number, special,
              uppercase and lowercase letter and should be at least 8 characters
              long.
            </p>

            <p style={{ color: 'red' }}>{feedbackText}</p>

            <FormInput
              label={'Temporary password'}
              image={['fas', 'lock']}
              value={password}
              onChange={event => setPassword(event.target.value)}
              type="password"
              faIcon={true}
            />

            <FormInput
              label={'New password'}
              value={newPassword}
              image={['fas', 'lock']}
              onChange={event => setNewPassword(event.target.value)}
              type="password"
              faIcon={true}
            />

            <FormInput
              label={'New password again'}
              value={newPassword2}
              image={['fas', 'lock']}
              onChange={event => setNewPassword2(event.target.value)}
              type="password"
              faIcon={true}
            />

            <Container>
              <Row>
                <Col className="text-center">
                  <Button type="submit">Update password</Button>
                </Col>
                <Col className="text-center">
                  <Link to="/login">
                    <Button className="btn-danger">Cancel</Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      </div>
    </Background>
  )
}

export default ChangePassword
