import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'

const getSignedImageUrl = async (bucket, region, credentials, key) => {
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const getObjectCommand = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  })
  try {
    const url = await getSignedUrl(s3Client, getObjectCommand, {
      expiresIn: 3600,
    })
    return url
  } catch (err) {
    console.error('GetSignedImageUrl', err)
  }
}

export default getSignedImageUrl
