import React, { useContext, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AccountContext from '../../context/AccountContext'

const ProtectedRoute = ({ children, page_title, ...kwargs }) => {
  const { selectedGroup, groupMap } = useContext(AccountContext)
  let isAllowed = false

  let allowedRoutes = groupMap(selectedGroup, 'routes')
  if (allowedRoutes === undefined || allowedRoutes === null) {
    isAllowed = false
  } else if (allowedRoutes.includes(page_title)) {
    isAllowed = true
  }

  return (
    <Route
      {...kwargs}
      render={() => {
        return isAllowed ? children : <Redirect to="/" />
      }}
    />
  )
}

export default ProtectedRoute
