import getSignedImageUrl from './base/GetSignedImageUrl'
import listObjects from './base/ListS3Objects'

const getMultipleImageUrls = async (bucket, region, credentials, path = '') => {
  // Accepts a path prefix and returns signed Urls for each of the images in the s3 prefix path

  const s3Objects = await listObjects(bucket, region, credentials, path)
  let signedUrls = {}
  for (let s3Object of s3Objects) {
    let key = s3Object.Key
    if (key.toLowerCase().endsWith('.jpg')) {
      // Continue to create a signed url
      signedUrls[key] = await getSignedImageUrl(
        bucket,
        region,
        credentials,
        key
      )
    }
  }

  return signedUrls
}

export default getMultipleImageUrls
