import { ListObjectsCommand, S3Client } from '@aws-sdk/client-s3'

const listObjects = async (bucket, region, credentials, prefix = '') => {
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const listObjectsCommand = new ListObjectsCommand({
    Bucket: bucket,
    Prefix: prefix,
  })
  try {
    let response = await s3Client.send(listObjectsCommand)
    let result = response.Contents
    while (response.IsTruncated === true) {
      response = await s3Client.send(
        new ListObjectsCommand({
          Bucket: bucket,
          Prefix: prefix,
          Marker: response.Contents[response.Contents.length - 1].Key,
        })
      )
      result = [...result, ...response.Contents]
    }
    return result
  } catch (err) {
    console.error('ListObjectsCommand', err)
  }
}

export default listObjects
