import { useContext, useEffect, useState } from 'react'
import AccountContext from '../context/AccountContext'
import ReactImageAnnotate from 'react-image-annotate'

import putJsonObject from './aws/base/PutJson'
import { Redirect } from 'react-router'
import CountingContext from '../context/CountingContext'
import putTextObject from './aws/base/PutText'

const ImageApp = () => {
  var BreakException = {}
  // const [savedPens, setSavedPens] = useState([]);
  const {
    loggedIn,
    creds,
    region,
    bucket,
    selectedGroup,
    groupMap,
  } = useContext(AccountContext)
  const {
    flight,
    operation,
    account,
    setOperation,
    setAccount,
    setFlight,
    done,
    setDone,
    displayImages,
    selectedImage,
    setSelectedImage,
    report,
    images,
    numberOfMatches,
    updates,
    changeHook,
    deleteHook,
    enabledTools,
    setFeedback,
    block,
    setBlock,
  } = useContext(CountingContext)

  const handleNext = () => {
    if (selectedImage === displayImages.length - 1) return
    setSelectedImage(selectedImage + 1)
  }
  const handlePrev = () => {
    if (selectedImage === 0) return
    setSelectedImage(selectedImage - 1)
  }

  function exitHook(history) {
    let savedPens = []
    if (groupMap(selectedGroup, 'edit') == false) {
      setFeedback('Read-only access.')
      return
    }
    if (loggedIn && block.length !== 0) {
      setFeedback('Saving...')
      try {
        history.images.forEach((image, i) => {
          let image_output = {}
          try {
            image_output['image_width'] = image['pixelSize']['w']
            image_output['image_height'] = image['pixelSize']['h']
            image_output['image_src'] = image['src']
          } catch (err) {
            return
          }
          image_output['regions'] = []
          if (image['regions'].length == 0) {
            console.log('Im:', image)
          }
          for (const region of image['regions']) {
            let region_json = {
              ...region,
            }
            const region_type = region['type']
            region_json['type'] = region_type
            let region_cls = undefined
            if (region['cls'] === undefined) {
              // Temp fix, assign class of cow
              region_cls = 'cow'
            } else {
              region_cls = region['cls']
                .replaceAll(' ', '-')
                .replaceAll(':', '_')
                .toLowerCase()
            }
            region_json['cls'] = region_cls
            region_json['color'] = region['color']
            image_output['regions'].push(region_json)
          }
          // Create and store json
          let im_loc = `${groupMap(
            selectedGroup,
            'json_location',
            operation
          )}/${account}/${flight}/${block}/${image.name}.json`
          putJsonObject(bucket, region, creds, image_output, im_loc).then(
            response => {
              if (response.$metadata.httpStatusCode == 200) {
                savedPens.push(image.name)
                setFeedback(`Saved:\n${savedPens.join('\n')}`)
              }
            }
          )
        })
      } catch (error) {
        // setFeedback('Error...')
        setFeedback('Error', error)
      }
    } else {
      alert('Not logged in or block length invalid')
      setFeedback('Unable to save...')
    }
  }

  if (done) {
    if (window.confirm('Did you save?') === true) {
      if (operation === 'feedlots') {
        putTextObject(
          'cattle-data',
          region,
          creds,
          `${groupMap(
            selectedGroup,
            'image_location',
            operation
          )}/${account}/${flight}/${block}/done.txt`
        )
      }
      setOperation('')
      setAccount('')
      setFlight('')
      setBlock('')
      setDone(false)
      return <Redirect to="/home" />
    } else {
      setFeedback('Please save..')
    }
  }

  return (
    <div className="image_app">
      {operation === 'report' ? (
        <div style={{ color: 'white' }}>
          <ul>
            {report.map((element, index) => (
              <li key={element}>{element}</li>
            ))}
          </ul>
        </div>
      ) : displayImages.length === numberOfMatches &&
        displayImages.length !== 0 ? (
        <ReactImageAnnotate
          style={{ color: 'white' }}
          labelImages
          regionClsList={operation === 'merged' ? [''] : ['Cow']}
          regionTagList={[]}
          enabledTools={enabledTools}
          selectedImage={selectedImage}
          onNextImage={handleNext}
          onPrevImage={handlePrev}
          images={displayImages}
          onExit={exitHook}
          deleteHook={deleteHook}
          changeHook={changeHook}
          key={updates}
          hideClone={true}
        />
      ) : (
        <div>{`${numberOfMatches}/${displayImages.length}`}</div>
      )}
    </div>
  )
}

export default ImageApp
