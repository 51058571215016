import React, { useState, useEffect, useContext } from 'react'
import UserPool from './UserPool'
import AccountContext from '../../context/AccountContext'
import { Link, useHistory } from 'react-router-dom'
import FormInput from '../shared/FormInput'

const Signup = () => {
  const { getSession } = useContext(AccountContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggedin, setLoggedin] = useState(false)

  const history = useHistory()

  useEffect(() => {
    getSession()
      .then(({ user }) => {
        setLoggedin(true)
      })
      .catch(err => {})
  }, [])

  const onSubmit = event => {
    event.preventDefault()

    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        console.error(err)
      }
      // console.log(data);
    })
  }

  const styles = {
    display: 'flex',
    background: `url(/bg3.jpg)`,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    //!loggedin &&
    <div style={styles}>
      <div className="home_page">
        <div className="left_side">
          <img
            src={'/chisl_logo.png'}
            alt=""
            width="400px"
            height="400px"
            style={{ marginTop: `-30px` }}
          />
          <h1
            className="display-2"
            style={{ marginTop: `40px`, fontFamily: `Permanent Marker` }}
          >
            Asset Verification
          </h1>
        </div>

        <div
          className="h-full d-flex flex-column justify-content-center align-items-center"
          style={{ height: `100%`, fontFamily: 'Bebas Neue' }}
        >
          <form
            onSubmit={onSubmit}
            className=" d-flex flex-column justify-content-center align-items-center"
          >
            {/*<p style={{color: 'red'}}>{feedbackText}</p>*/}

            <FormInput
              label={'Username/Email'}
              image={'email.png'}
              value={email}
              onChange={event => setEmail(event.target.value)}
              type="text"
              style={{ margin: '0 0 35px 0', textTransform: 'lowercase' }}
            />

            <FormInput
              label={'Password'}
              value={password}
              image={'password.png'}
              onChange={event => setPassword(event.target.value)}
              type="password"
              style={{ margin: '0 0 20px 0' }}
            />

            <button
              className="upload_btn"
              type="submit"
              onClick={() => history.push('/login')}
            >
              {' '}
              Sign Up{' '}
            </button>
          </form>
          <div
            className="d-flex flex-row justify-content-end"
            style={{ width: '100%' }}
          >
            <Link to="/login"> Login </Link>
          </div>
        </div>
      </div>
    </div>

    // <div>
    //   <form onSubmit={onSubmit}>
    //     <label htmlFor="email">Email</label>
    //     <input
    //       value={email}
    //       onChange={(event) => setEmail(event.target.value)}
    //     ></input>
    //     <label htmlFor="password">Password</label>
    //     <input
    //       value={password}
    //       onChange={(event) => setPassword(event.target.value)}
    //     ></input>
    //
    //     <button type="submit">Signup</button>
    //     <button onClick={()=> history.push("/login")}>Login</button>
    //   </form>
    // </div>
  )
}

export default Signup
