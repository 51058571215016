import { useState, useContext } from 'react'
import {
  Col,
  Row,
  Container,
  Table,
  Button,
  Form,
  Modal,
} from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import Background from '../components/shared/Background'
import AccountContext from '../context/AccountContext'
import listObjectsForPrefix from '../components/aws/ListObjectsForPrefix'
import getJsonForPath from '../components/aws/GetJsonForFlight'
import Multiselect from 'multiselect-react-dropdown'
import { useEffect } from 'react'
import putJsonObject from '../components/aws/base/PutJson'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

const AdminPageUsers = () => {
  const {
    creds,
    region,
    bucket,
    selectedGroup,
    groupMap,
    userConfig,
  } = useContext(AccountContext)
  const headers = [
    { key: 'id', display: 'ID' },
    { key: 'name', display: 'Name' },
    { key: 'email', display: 'Email' },
    { key: 'permissions', display: 'Permissions' },
    { key: 'save', display: 'Save' },
  ]
  const [userPermissions, setUserPermissions] = useState([])
  const [userPermissionsDisplay, setUserPermissionsDisplay] = useState([])

  const [options, setOptions] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [addUserPermissions, setAddUserPermissions] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [addUserButtonActive, setAddUserButtonActive] = useState(false)
  const [addUserSaveButtonLoading, setAddUserSaveButtonLoading] = useState(
    false
  )
  const [addUserModalErrorMessage, setAddUserModalErrorMessage] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [s3FolderName, setS3FolderName] = useState('')
  const [pageHeading, setPageHeading] = useState('')

  const history = useHistory()

  function refreshUsersOrLabeler() {
    let url = window.location.href

    if (url.includes('admin-labelers-counters')) {
      setS3FolderName('users_labeler_counter')
      setPageHeading('Labellers and Counters')
      return 'users_labeler_counter'
    } else {
      setS3FolderName('users')
      setPageHeading('Users')
      return 'users'
    }
  }

  const refresh = async () => {
    const s3FolderNameForRefresh = refreshUsersOrLabeler()

    // Don't refresh if credentials have not been loaded
    if (Object.keys(creds).length === 0) {
      return
    }

    setAddUserButtonActive(false)
    setUserPermissions([])
    setShowLoading(true)

    // Ping backend for users directory
    let accounts = await listObjectsForPrefix(
      bucket,
      region,
      creds,
      'feedlots/',
      1
    )
    let users = await getJsonForPath(
      bucket,
      region,
      creds,
      s3FolderNameForRefresh + '/'
    )
    accounts = accounts.map((account, index) => {
      return { id: index, value: account }
      // return <option value={account}>{account}</option>
    })
    console.log('Found users:', users)
    setUserPermissions(
      Object.keys(users).map(id => {
        return {
          id: id,
          name: users[id]['farmerName'],
          email: users[id]['email'],
          permissions: accounts.filter(account =>
            users[id]['userPrefix'].includes(account['value'])
          ),
        }
      })
    )
    setUserPermissionsDisplay(
      Object.keys(users).map(id => {
        return {
          id: id,
          name: users[id]['farmerName'],
          email: users[id]['email'],
          permissions: accounts.filter(account =>
            users[id]['userPrefix'].includes(account['value'])
          ),
        }
      })
    )
    console.log('Found accounts:', accounts)
    setOptions(accounts)

    setShowLoading(false)
    setAddUserButtonActive(true)
  }

  const addUser = async e => {
    e.preventDefault()

    let newUserID = e.target[0].value
    newUserID = newUserID.toLowerCase().trim()
    let farmerName = e.target[1].value
    let email = e.target[2].value
    let permissions = addUserPermissions.map(p => p.value)

    // Input validation:
    if (
      newUserID === '' ||
      farmerName === '' ||
      email === '' ||
      permissions.length === 0
    ) {
      setAddUserModalErrorMessage('Please fill in all fields before continuing')
      return
    }

    setAddUserModalErrorMessage('')
    setAddUserSaveButtonLoading(true)

    let newUser = {
      farmerName: farmerName,
      email: email,
      userPrefix: permissions,
    }

    await putJsonObject(
      bucket,
      region,
      creds,
      newUser,
      `${s3FolderName}/${newUserID}.json`
    )

    let newUserForUi = {
      name: farmerName,
      id: newUserID,
      email: email,
      permissions: addUserPermissions,
    }
    setAddUserPermissions([])
    setUserPermissions(prevUserPermissions => {
      return [...prevUserPermissions, newUserForUi]
    })
    setShowModal(false)
    setAddUserSaveButtonLoading(false)
  }

  async function searchClicked(e) {
    e.preventDefault()

    if (!searchInput || searchInput === '') {
      setUserPermissionsDisplay(userPermissions)
      return
    }

    const searchInputLower = searchInput.toLowerCase()
    setUserPermissionsDisplay(
      userPermissions.filter(userpermission => {
        if (
          userpermission.name &&
          userpermission.name.toLowerCase().includes(searchInputLower)
        ) {
          return true
        }

        if (
          userpermission.email &&
          userpermission.email.toLowerCase().includes(searchInputLower)
        ) {
          return true
        }

        return false
      })
    )
  }

  // Load all user data on page load:
  useEffect(() => {
    refresh()
  }, [creds])

  const PermissionRow = ({ user, index }) => {
    const [userNameAsSaved, setUserNameAsSaved] = useState(user.name)
    const [userEmailAsSaved, setUserEmailAsSaved] = useState(user.email)
    const [userPermissionsAsSaved, setUserPermissionsAsSaved] = useState(
      user.permissions
    )

    const [saveButtonActive, setSaveButtonActive] = useState(false)
    const [userName, setUserName] = useState(user.name)
    const [userEmail, setUserEmail] = useState(user.email)
    const [selectedPermissions, setSelectedPermissions] = useState(
      user.permissions
    )

    // Function that checks changes against what they were,
    // disables save button if no changes were made
    function checkChangesForSaveButton(newName, newEmail, newPermissions) {
      if (newEmail === userEmailAsSaved && newName === userNameAsSaved) {
        if (newPermissions.length == userPermissionsAsSaved.length) {
          let arr1 = _.sortBy(newPermissions, Object.keys)
          let arr2 = _.sortBy(userPermissionsAsSaved, Object.keys)
          if (_.isEqual(arr1, arr2)) {
            setSaveButtonActive(false)
          }
        }
      }
    }

    // Function saves new user information
    async function saveUpdatedUser() {
      setSaveButtonActive(false)

      // Create user permissions json
      let updatedUser = {
        farmerName: userName,
        email: userEmail,
        userPrefix: selectedPermissions.map(per => per.value),
      }

      await putJsonObject(
        bucket,
        region,
        creds,
        updatedUser,
        `${s3FolderName}/${user.id}.json`
      )

      setUserNameAsSaved(userName)
      setUserEmailAsSaved(userEmail)
      setUserPermissionsAsSaved(selectedPermissions)
    }

    return (
      <tr key={index}>
        <td key={0}>{index + 1}</td>
        <td key={1}>
          <Form.Control
            className="text-black"
            readOnly
            defaultValue={user.id}
          />
        </td>
        <td key={2}>
          <Form.Control
            className="text-black"
            value={userName}
            onChange={e => {
              setUserName(e.target.value)
              setSaveButtonActive(true)
              // If the user changed but changed back to what is currently stored in db/s3, don't make save active
              checkChangesForSaveButton(
                e.target.value,
                userEmail,
                selectedPermissions
              )
            }}
          />
        </td>
        <td key={3}>
          <Form.Control
            className="text-black"
            value={userEmail}
            onChange={e => {
              setUserEmail(e.target.value)
              setSaveButtonActive(true)
              // If the user changed but changed back to what is currently stored in db/s3, don't make save active
              checkChangesForSaveButton(
                userName,
                e.target.value,
                selectedPermissions
              )
            }}
          />
        </td>
        <td className="w-25">
          <Multiselect
            options={options}
            selectedValues={selectedPermissions}
            onSelect={e => {
              console.log('Selected', e)
              setSelectedPermissions(e)
              setSaveButtonActive(true)
              checkChangesForSaveButton(userName, userEmail, e)
            }}
            onRemove={e => {
              console.log('Removing', e)
              setSelectedPermissions(e)
              setSaveButtonActive(true)
              checkChangesForSaveButton(userName, userEmail, e)
            }}
            displayValue="value"
            style={{
              option: {
                color: 'black',
              },
            }}
          />
        </td>
        <td className="w-10">
          <Button onClick={saveUpdatedUser} disabled={!saveButtonActive}>
            Save
          </Button>
        </td>
      </tr>
    )
  }

  return (
    // <Background>
    <div style={{ height: '100vh', width: '100vw', position: 'absolute' }}>
      <Modal show={showModal}>
        <Form onSubmit={addUser}>
          <Modal.Header>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Form.Group>
                <Form.Label>ID</Form.Label>
                <Form.Control
                  className="text-black"
                  type="text"
                  placeholder="Enter user id from Cognito"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Name and Surname</Form.Label>
                <Form.Control
                  className="text-black"
                  type="text"
                  placeholder="Enter user name and surname (optional)"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  className="text-black"
                  type="email"
                  placeholder="Enter user email"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Accessable accounts</Form.Label>
                <Multiselect
                  className="text-black"
                  options={options}
                  selectedValues={[]}
                  onSelect={p => {
                    setAddUserPermissions(p)
                  }}
                  onRemove={p => {
                    setAddUserPermissions(p)
                  }}
                  displayValue="value"
                />
              </Form.Group>
              <p style={{ color: 'red', margin: '10px 0 0 0' }}>
                {addUserModalErrorMessage}
              </p>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={addUserSaveButtonLoading}
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={addUserSaveButtonLoading}
            >
              {addUserSaveButtonLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="dark"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                'Save'
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <h3 style={{ color: 'white' }}>{pageHeading}</h3>
      </div>

      <Container className="overflow-hidden" style={{ height: '100vh' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 30px 30px 30px',
            alignItems: 'center',
          }}
        >
          <Button
            style={{ margin: '20px 0 0 0', maxHeight: '40px' }}
            onClick={() => history.push('/home')}
          >
            Home
          </Button>
          <Button
            style={{ margin: '20px 0 0 0', maxHeight: '40px' }}
            onClick={refresh}
            disabled={showLoading}
          >
            Refresh
          </Button>
          <Button
            style={{ margin: '20px 0 0 0', maxHeight: '40px' }}
            onClick={() => setShowModal(true)}
            disabled={!addUserButtonActive}
          >
            Add User
          </Button>
          <div id="searchbar" style={{ display: 'flex', margin: '20px 0 0 0' }}>
            <Form.Control
              type="text"
              id="inputPassword5"
              style={{ color: 'black' }}
              onChange={e => {
                setSearchInput(e.target.value)
              }}
            />
            <Button
              style={{ maxHeight: '40px' }}
              onClick={async e => {
                await searchClicked(e)
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </div>
        </div>

        <Form>
          <Row>
            <Col>
              <div
                className={showLoading ? 'h-50' : 'vh-100 overflow-scroll'}
                style={{ padding: showLoading ? '0 0 0 0' : '0 0 200px 0' }}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      {headers.map((col, index) => {
                        return <th key={index}>{col['display']}</th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {userPermissionsDisplay.map((user, index) => {
                      return <PermissionRow user={user} index={index} />
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Form>

        {showLoading && (
          <div
            style={{
              margin: '40px 0 0 0',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status" variant="light">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </Container>
    </div>
    // </Background>
  )
}
export default AdminPageUsers
