import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'
import AccountContext from './context/AccountContext'

import { Header } from './components/Header'
import Home from './routes/Home'
import Login from './routes/Login'
import ImageApp from './components/ImageAnnotator'

// import UploadFiles from "./components/UploadFiles";
import Upload from './routes/Upload'
import { AccountProvider } from './context/AccountContext'
import Status from './components/auth/Status'
import Signup from './components/auth/Signup'
import { CountingProvider } from './context/CountingContext'
import PrintReport from './components/PrintReport'
import { NavBar } from './components/NavBar'
import ChangePassword from './routes/ChangePassword'
import Profile from './routes/Profile'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Logout from './routes/Logout'
import ProtectedRoute from './components/auth/ProtectedRoute'
import AdminPageUsers from './routes/AdminUsers'
import Background from './components/shared/Background'

library.add(fas)

function App() {
  return (
    <Router>
      <Background>
        <AccountProvider>
          <Switch>
            <Route path="/status">
              <Status />
            </Route>

            <Route path="/signup">
              <Signup />
            </Route>

            <Route path="/login">
              <Login />
            </Route>

            <Route path="/change_password">
              <ChangePassword />
            </Route>

            <Route path="/pdf">
              <PrintReport />
            </Route>

            <ProtectedRoute path="/imageapp" page_title="ImageApp">
              <CountingProvider>
                <NavBar>
                  <ImageApp />
                </NavBar>
              </CountingProvider>
            </ProtectedRoute>

            <ProtectedRoute path="/upload" page_title="Upload">
              <Upload />
            </ProtectedRoute>

            <Route path="/profile">
              <Profile />
            </Route>

            <Route path="/logout">
              <Logout />
            </Route>

            {/* <Route path="/admin">
              <AdminPage />
            </Route> */}

            <ProtectedRoute path="/admin-users" page_title="Admin">
              <AdminPageUsers />
            </ProtectedRoute>

            <ProtectedRoute path="/admin-labelers-counters" page_title="Admin">
              <AdminPageUsers />
            </ProtectedRoute>

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </AccountProvider>
      </Background>
    </Router>
  )
}

export default App
