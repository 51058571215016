import React, { useState, useContext, useEffect } from 'react'
import AccountContext from '../context/AccountContext'
import { Redirect, Route } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import NavButton from '../components/home/NavButton'
import FormInput from '../components/shared/FormInput'
import Background from '../components/shared/Background'
import CompanyLogo from '../components/shared/CompanyLogo'
import Loading from '../components/Loading'
import { Container, Col, Row, Button } from 'react-bootstrap'
import SideBar from '../components/shared/SideBar'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loggedin, setLoggedin] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [feedbackText, setFeedbackText] = useState('')

  const { authenticate, getSession, newPasswordRequired } = useContext(
    AccountContext
  )

  const onSubmit = event => {
    setLoading(true)
    event.preventDefault()

    if (/.+/.test(email) && /.+/.test(password)) {
      authenticate(email, password)
        .then(data => {
          setLoggedin(true)
          setLoading(false)
        })
        .catch(err => {
          setFeedbackText(`${err}`)
          setLoggedin(false)
          setLoading(false)
        })
    } else {
      setLoading(false)
      setFeedbackText('Please fill in valid entries')
    }
  }

  useEffect(() => {
    getSession()
      .then(({ user }) => {
        setLoggedin(true)
      })
      .catch(err => {})
  }, [])
  const styles = {
    display: 'flex',
    background: `url(/bg3.jpg)`,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  }

  if (loggedin) {
    if (newPasswordRequired) return <Redirect to="/change_password" />

    return <Redirect to="/home" />
  }

  return (
    <Container fluid className="p-0">
      <Row className="vh-100 justify-content-center">
        <SideBar size="col">
          <NavButton
            linkLocation={'/home'}
            imageUrl={['fas', 'arrow-left']}
            displayText={'Back'}
            faIcon={true}
            btnwidth="12vw"
          />
        </SideBar>
        <Col>
          <Row className="vh-100 justify-content-center align-items-center">
            <Col>
              {loading ? (
                <Loading />
              ) : (
                <form
                  onSubmit={onSubmit}
                  className=" d-flex flex-column justify-content-center align-items-center"
                >
                  <p style={{ color: 'red' }}>{feedbackText}</p>

                  <FormInput
                    label={'Email'}
                    image={['fas', 'envelope']}
                    value={email}
                    onChange={event => setEmail(event.target.value.trim())}
                    type="text"
                    faIcon={true}
                  />

                  <FormInput
                    label={'Password'}
                    value={password}
                    image={['fas', 'lock']}
                    onChange={event => setPassword(event.target.value)}
                    type="password"
                    faIcon={true}
                  />

                  <Button type="submit">Login</Button>
                </form>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
