import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import AccountContext from '../context/AccountContext'
import putJsonObject from '../components/aws/base/PutJson'
import putTextObject from '../components/aws/base/PutText'
import FormInput from '../components/shared/FormInput'
import UploadForm from '../components/shared/UploadForm'
import FormCheckbox from '../components/shared/FormCheckbox'
import uploadImage from '../components/aws/base/UploadImage'
import { Redirect } from 'react-router-dom'
import Background from '../components/shared/Background'
import SideBar from '../components/shared/SideBar'
import NavButton from '../components/home/NavButton'
import { ConnectContactLens } from 'aws-sdk'

function Upload() {
  const [account, setAccount] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [jsonSuccess, setJsonSuccess] = useState(false)
  const [imagesUploaded, setImagesUploaded] = useState(0)
  let numImagesCurUploaded = 0
  const [numImagesToUpload, setNumImagesToUpload] = useState(0)
  const [farmer, setFarmer] = useState('')
  const [farm, setFarm] = useState('')
  const [field, setField] = useState('')
  const [flight, setFlight] = useState('')
  const {
    creds,
    region,
    selectedGroup,
    userConfig,
    loggedIn,
    groupMap,
  } = useContext(AccountContext)
  const [files, setFiles] = useState({})
  const [isField, setIsField] = useState(false)
  const [uploadItems, setUploadItems] = useState([{ index: 0, text: '' }])

  useEffect(() => {
    if (selectedGroup.toLowerCase() == 'user') {
      setAccount(userConfig['userPrefix'])
    }
  }, [])

  if (loggedIn == false && groupMap(selectedGroup, 'upload')) {
    return <Redirect to={'/home'} />
  }

  const duplicate = () => {
    let newIndex = uploadItems.reduce((prev, curr) => {
      return prev > curr.index ? prev : curr.index
    }, 0)
    setUploadItems([...uploadItems, { index: newIndex + 1, text: '' }])
  }
  const subtract = index => {
    let len = uploadItems.length

    // if (len > 1) setUploadItems(uploadItems.slice(0, len - 1));

    if (len > 1)
      setUploadItems(
        uploadItems.filter(uploadItem => uploadItem.index !== index)
      )
  }

  const updateFiles = (newFiles, blockName) => {
    let tempFiles = { ...files }
    tempFiles[blockName] = newFiles
    setFiles({ ...tempFiles })
  }

  // Handles the upload button click
  // This function has to check that all values are filled in,
  // map all the file objects to the correct format so that it can be uploaded,
  // determines where to upload the files based upon the the isField checkbox,
  // and then start the uploading process.
  const handleClick = event => {
    event.preventDefault()
    // console.log(files);
    let mappedFiles = []
    Object.keys(files).map(key => {
      mappedFiles.push(
        ...Array.from(files[key]).map(file => {
          return {
            blockName: key,
            file,
          }
        })
      )
    })

    let missing = []
    if (account === '') {
      missing.push('Account')
    }
    if (farmer === '') {
      missing.push('Farmer')
    }
    if (farm === '') {
      missing.push('Farm')
    }
    if (field === '') {
      missing.push('Field')
    }
    if (missing.length > 0) {
      setValidationMessage(`Please fill in: ${missing.join('; ')}`)
      return
    } else {
      setValidationMessage('')
    }
    let operation = isField ? 'images' : 'feedlots' //change this to images later
    let flight = `flight_${new Date()
      .toISOString()
      .replaceAll(':', '_')
      .replaceAll('.', '_')}`
    setFlight(flight)

    // First upload the info
    putJsonObject(
      'cattle-data',
      region,
      creds,
      {
        account: account,
        farmer: farmer,
        farm: farm,
        field: field,
      },
      `info/${account}/${flight}/info.json`
    ).then(response => {
      setJsonSuccess(response.$metadata.httpStatusCode == 200)
    })
    // console.log('MappedFiles:', mappedFiles.length)
    setNumImagesToUpload(mappedFiles.length)
    setImagesUploaded(0)

    readImages(mappedFiles, operation, account, flight, creds)
  }

  // Recursive upload function. This is so that each file gets uploaded one after the other
  // and doesn't cause a timeout on slow connections.
  async function readImages(images, operation, account, flight, creds) {
    // console.log('ReadImages')
    // console.log('Reading first file')
    readFile(0, 0, images, operation, flight)
  }
  const readFile = (index, retries, images, operation, flight) => {
    if (index >= images.length) {
      // console.log(`Files finished uploading`);
      return
    }
    if (retries === 3) {
      console.error(`CRASH AND BURN ${file}`)
      return
    }

    let blockName = images[index].blockName
    let file = images[index].file
    // console.log('blockname', blockName)
    // console.log('file',file)

    const storageLocation = `${operation}/${account}/${flight}/${blockName}/${file[
      'name'
    ].toLowerCase()}`
    // console.log('storagelocation',storageLocation)
    uploadImage('cattle-data', region, creds, file, storageLocation)
      .then(response => {
        // console.log('response',response);
        if (response.$metadata.httpStatusCode == 200) {
          // Set default to true, and have a failed one trip it to false.
          setImagesUploaded(index + 1)
          readFile(index + 1, 0, images, operation, flight)
        }
      })
      .catch(err => {
        // console.log(`Error uploading "${file['name'].toLowerCase()}: "`, err);
        // console.log(`Retrying index ${index}`)
        readFile(index, retries + 1, images, operation, flight)
      })
  }

  // Function triggers at the end as it is built into a react component
  // and rerenders as the state of its inputs changes.
  // This checks that all images are uploaded and pastes a done.txt file
  // in the appropriate location
  function feedback(jsonSuccess, imagesUploaded, numImagesToUpload) {
    let imagesSuccess = imagesUploaded == numImagesToUpload
    if (jsonSuccess && imagesSuccess) {
      if (isField) {
        Object.keys(files).map(block => {
          putTextObject(
            'cattle-data',
            region,
            creds,
            `images/${account}/${flight}/${block}/done.txt`
          )
        })
      }
      return 'Operation successful!'
    } else {
      return ''
    }
  }
  return (
    // <Background>
    <div
      className="row no-gutters text-white p-0 m-0"
      style={{ height: '100vh' }}
    >
      <SideBar size="col">
        <NavButton
          linkLocation={'/home'}
          imageUrl={['fas', 'arrow-left']}
          displayText={'Back'}
          faIcon={true}
          btnwidth="12vw"
        />
      </SideBar>
      <div className="col">
        <form onSubmit={handleClick} className="">
          <div className="row">
            <div className="col text-center">
              <p className="text-red">{validationMessage}</p>
              <p className="text-green">
                {feedback(jsonSuccess, imagesUploaded, numImagesToUpload)}
              </p>
              <h2>Upload files</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-6">
              <FormInput
                value={account}
                type="text"
                image={['fas', 'user']}
                label={'Account'}
                setValue={setAccount}
                disable={selectedGroup.toLowerCase() == 'user'}
                faIcon={true}
              />
              <FormInput
                value={farmer}
                type="text"
                image={['fas', 'tractor']}
                label={'Farmer'}
                setValue={setFarmer}
                faIcon={true}
              />
              <FormInput
                value={farm}
                type="text"
                image={['fas', 'wheat-awn']}
                label={'Farm'}
                setValue={setFarm}
                faIcon={true}
              />
              <FormInput
                value={field}
                type="text"
                image={['fas', 'cow']}
                label={isField ? 'Field' : 'Feedlot'}
                setValue={setField}
                faIcon={true}
              />
              <FormCheckbox label={'Field?'} setFunction={setIsField} />
            </div>
            <div className="col-6">
              <div className="col">
                {uploadItems.map(function(upd, i) {
                  return (
                    <div key={i}>
                      {' '}
                      <UploadForm
                        updateFiles={updateFiles}
                        subtract={subtract}
                        index={upd.index}
                      />{' '}
                    </div>
                  )
                })}
              </div>
              <div className="col">
                {/* <a href="#" onClick={duplicate}> <img src={`add.png`} alt="" width="30px" height="30px" /> </a> */}
                <div className="btn text-primary m-1" onClick={duplicate}>
                  <FontAwesomeIcon icon={['fas', 'circle-plus']} size="xl" />
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="row align-items-center justify-content-center px-5">
            <div className="col-4">
              <button type="submit" className="btn btn-primary w-100">
                {' '}
                Upload{' '}
              </button>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-2">
              <p className="text-center">
                Uploaded {imagesUploaded} / {numImagesToUpload}
              </p>
            </div>
          </div>
        </form>
        <div className=" input_form"></div>
      </div>
    </div>
    // </Background>
  )
}

export default Upload
