import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'

const getObject = async (bucket, region, credentials, key) => {
  const s3Client = new S3Client({
    credentials: credentials,
    region: region,
  })
  const getObjectsCommand = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
    Range: 'json',
  })
  try {
    let response = await s3Client.send(getObjectsCommand)
    return response
  } catch (err) {
    // if the key doesn't exist then return null
    if (err.name === 'NoSuchKey') {
      return null
    }
    console.error('GetS3Object', err)
  }
}

export default getObject
