import React from 'react'
import { Dropdown } from 'react-bootstrap'
// import {Dropdown} from 'reactstrap';

function DropDown({ label, image, placeholder, items, setValue }) {
  return (
    <div className="dropdown">
      <Dropdown>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="dropdown_btn bg-primary"
          style={{ overflow: 'hidden' }}
        >
          {label !== '' ? label : placeholder}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: `25rem`, overflowY: `scroll` }}>
          {items !== undefined ? (
            items.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  className="overflow-hidden"
                  onClick={e => {
                    e.preventDefault()
                    setValue(item)
                  }}
                >
                  {item}
                </Dropdown.Item>
              )
            })
          ) : (
            <li>No selection</li>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default DropDown
