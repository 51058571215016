import { Link } from 'react-router-dom'
import CompanyLogo from './CompanyLogo'
const SideBar = ({ size = 'col-3', children }) => {
  if (children instanceof Object) {
    children = [children]
  }
  return (
    <div
      className={`col pt-3`}
      style={{
        maxWidth: '18rem',
        minWidth: '15rem',
        backgroundColor: '#515959',
      }}
    >
      <div className="row text-center">
        <CompanyLogo notext size="col-12" imsize="100%" />
      </div>
      {children.map((child, childId) => {
        return (
          <div key={childId} className="row text-center text-white my-3">
            {child}
          </div>
        )
      })}
    </div>
  )
}
export default SideBar
